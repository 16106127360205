import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './Inspection.css'
import Axios from 'axios'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import dbData from '../db/dbdata.json'
import dbMakes from '../db/dbMakes.json'
import dbModels from '../db/dbModels.json'
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
import logo from '../assets/image/logo.png'


  const authentication = e => {    

    e.preventDefault()
    const form = e.target    

   const data = {
    "IdInspection": form.IdInspection.value,
    "Lincense": form.Lincense.value,
    "Date": form.Date.value,
    "Year": form.Year.value,
    "Make": form.Make.value,
    "Model":form.Model.value,
    "Vin":form.Vin.value,
    "Odometer": form.Odometer.value,
    "CoolingWaterPump": form.CoolingWaterPump.value,
    "CoolingEngineFanMotor": form.CoolingEngineFanMotor.value,
    "CoolingFanClutchShroud": form.CoolingFanClutchShroud.value,
    "CoolingCoolantRecoveryUnit": form.CoolingCoolantRecoveryUnit.value,
    "CoolingBeltsHoses": form.CoolingBeltsHoses.value,
    "CoolingTemperatureLightGauge": form.CoolingTemperatureLightGauge.value,
    "CoolingFluidLeaks": form.CoolingFluidLeaks.value,
    "CoolingFuelPumpTankLines": form.CoolingFuelPumpTankLines.value,
    "CoolingDieselInjectorsPump": form.CoolingDieselInjectorsPump.value,
    "TransmissionFluidLevelCondition": form.TransmissionFluidLevelCondition.value,
    "TransmissionShiftPoints": form.TransmissionShiftPoints.value,
    "TransmissionSlippingBinding": form.TransmissionSlippingBinding.value,
    "TransmissionNoise": form.TransmissionNoise.value,
    "TransmissionMounts": form.TransmissionMounts.value,
    "TransmissionFluidLeaks": form.TransmissionFluidLeaks.value,
    "BrakeFluidLeaksLinesFiltering": form.BrakeFluidLeaksLinesFiltering.value,
    "BrakeMasterCylinder": form.BrakeMasterCylinder.value,
    "BrakePumpBooster": form.BrakePumpBooster.value,
    "BrakeLiningsFronts": form.BrakeLiningsFronts.value,
    "BrakeLiningsRears": form.BrakeLiningsRears.value,
    "TreadLeftFront": form.TreadLeftFront.value,
    "TreadLeftRear": form.TreadLeftRear.value,
    "EnginePerformance": form.EnginePerformance.value,
    "EngineNoise": form.EngineNoise.value,
    "EngineOilPressureLightGauge": form.EngineOilPressureLightGauge.value,
    "EngineMotorMounts": form.EngineMotorMounts.value,
    "EngineExhaustLeak": form.EngineExhaustLeak.value,
    "EngineOilLevelCondition": form.EngineOilLevelCondition.value,
    "EngineOilLeaks": form.EngineOilLeaks.value,
    "EngineOilSmoke": form.EnginePerformance.value,
    "DriveVibration": form.DriveVibration.value,
    "DriveNoise": form.DriveNoise.value,
    "DriveFluidLeaks": form.DriveFluidLeaks.value,
    "DriveCVUJoints": form.DriveCVUJoints.value,
    "SuspensionControlHarms": form.SuspensionControlHarms.value,
    "SuspensionBallJoints": form.SuspensionBallJoints.value,
    "SuspensionWheelBearings": form.SuspensionWheelBearings.value,
    "SuspensionTorsionBarsSprings": form.SuspensionTorsionBarsSprings.value,
    "SuspensionStabilizerShaftLinks": form.SuspensionStabilizerShaftLinks.value,
    "SuspensionMacPhersonStruts": form.SuspensionMacPhersonStruts.value,
    "SteeringFluidLevelLeak": form.SteeringFluidLevelLeak.value,
    "SteeringPump": form.SteeringPump.value,
    "SteeringLinkage": form.SteeringLinkage.value,
    "SteeringRackPinionGearBox": form.SteeringRackPinionGearBox.value,
    "AirCompressorclutch": form.AirCompressorclutch.value,
    "AirCondensor": form.AirCondensor.value,
    "AirModeControl": form.AirModeControl.value,
    "ElectricalChargingSystem": form.ElectricalChargingSystem.value,
    "ElectricalStarter": form.ElectricalStarter.value,
    "ElectricalPowerWindowMotor": form.ElectricalPowerWindowMotor.value,
    "ElectricalPowerSeatsMotor": form.ElectricalPowerSeatsMotor.value,
    "ElectricalHorn": form.ElectricalHorn.value,
    "ElectricalPowerLocks": form.ElectricalPowerLocks.value,
    "ElectricalPowerRoofMotor": form.ElectricalPowerRoofMotor.value,
    "ElectricalPowerAntenaAudio": form.ElectricalPowerAntenaAudio.value,
    "ElectricalCruiseControl": form.ElectricalCruiseControl.value,
    "ElectricalPowerMirrors": form.ElectricalPowerMirrors.value,
    "ElectricalRearDefrost": form.ElectricalRearDefrost.value,
    "ElectricalWiperMotorBlades": form.ElectricalWiperMotorBlades.value,
    "ElectricalLights": form.ElectricalLights.value,
    "Comments": form.Comments.value,
  }  
      Axios.post(process.env.REACT_APP_CREATEINSPECTION, data)
    .then(r => {
      localStorage.setItem('token', r.data.token)
      window.location = "/"
    }).then(() => alert('The Inspection has been succesfully saved'))
    .catch(e => {
      alert('error al iniciar sesion')
    } )
}



class Inspection extends React.Component  { 
    constructor(props) {
        super(props);
        this.state = { coolingWaterPump: "" };
        this.state = { coolingEngineFanMotor: "" };
        this.state = { coolingFanClutchShroud: "" };
        this.state = { coolingCoolantRecoveryUnit: "" };
        this.state = { coolingBeltsHoses: "" };
        this.state = { coolingTemperatureLightGauge: "" };
        this.state = { coolingFluidLeaks: "" };
        this.state = { coolingFuelPumpTankLines: "" };
        this.state = { coolingDieselInjectorsPump: "" };

        this.state = { transmissionFluidLevelCondition: "" };
        this.state = { transmissionShiftPoints: "" };
        this.state = { transmissionSlippingBinding: "" };
        this.state = { transmissionNoise: "" };
        this.state = { transmissionMounts: "" };
        this.state = { transmissionFluidLeaks: "" };

        this.state = { brakeFluidLeaksLinesFiltering: "" };
        this.state = { brakeMasterCylinder: "" };
        this.state = { brakePumpBooster: "" };
        this.state = { brakeLiningsFronts: "" };
        this.state = { brakeLiningsRears: "" };
        this.state = { treadLeftFront: "" };
        this.state = { treadLeftRear: "" };

        this.state = { enginePerformance: "" };
        this.state = { engineNoise: "" };
        this.state = { engineOilPressureLightGauge: "" };
        this.state = { engineMotorMounts: "" };
        this.state = { engineExhaustLeak: "" };
        this.state = { engineOilLevelCondition: "" };
        this.state = { engineOilLeaks: "" };
        this.state = { engineOilSmoke: "" };

        this.state = { driveVibration: "" };
        this.state = { driveNoise: "" };
        this.state = { driveFluidLeaks: "" };
        this.state = { driveCVUJoints: "" };

        this.state = { suspensionControlHarms: "" };
        this.state = { suspensionBallJoints: "" };
        this.state = { suspensionWheelBearings: "" };
        this.state = { suspensionTorsionBarsSprings: "" };
        this.state = { suspensionStabilizerShaftLinks: "" };
        this.state = { suspensionMacPhersonStruts: "" };

        this.state = { steeringFluidLevelLeak: "" };
        this.state = { steeringPump: "" };
        this.state = { steeringLinkage: "" };
        this.state = { steeringRackPinionGearBox: "" };

        this.state = { airCompressorclutch: "" };
        this.state = { airCondensor: "" };
        this.state = { airModeControl: "" };

        this.state = { electricalChargingSystem: "" };
        this.state = { electricalStarter: "" };
        this.state = { electricalPowerWindowMotor: "" };
        this.state = { electricalPowerSeatsMotor: "" };
        this.state = { electricalHorn: "" };
        this.state = { electricalPowerLocks: "" };
        this.state = { electricalPowerRoofMotor: "" };
        this.state = { electricalPowerAntenaAudio: "" };
        this.state = { electricalCruiseControl: "" };
        this.state = { electricalPowerMirrors: "" };
        this.state = { electricalRearDefrost: "" };
        this.state = { electricalWiperMotorBlades: "" };
        this.state = { electricalLights: "" };


      }

      ChangeHandlerCoolingWaterPump              = (event) =>  { this.setState({coolingWaterPump:            event.target.value})} 
      ChangeHandlerCoolingEngineFanMotor         = (event) =>  { this.setState({coolingEngineFanMotor:       event.target.value})}
      ChangeHandlerCoolingFanClutchShroud        = (event) =>  { this.setState({coolingFanClutchShroud:      event.target.value})}
      ChangeHandlerCoolingCoolantRecoveryUnit    = (event) =>  { this.setState({coolingCoolantRecoveryUnit:  event.target.value})}
      ChangeHandlerCoolingBeltsHoses             = (event) =>  { this.setState({coolingBeltsHoses           :  event.target.value})}
      ChangeHandlerCoolingTemperatureLightGauge  = (event) =>  { this.setState({coolingTemperatureLightGauge:  event.target.value})}
      ChangeHandlerCoolingFluidLeaks             = (event) =>  { this.setState({coolingFluidLeaks           :  event.target.value})}
      ChangeHandlerCoolingFuelPumpTankLines      = (event) =>  { this.setState({coolingFuelPumpTankLines    :  event.target.value})}
      ChangeHandlerCoolingDieselInjectorsPump    = (event) =>  { this.setState({coolingDieselInjectorsPump  :  event.target.value})}

      ChangeHandlerTransmissionFluidLevelCondition    = (event) =>  { this.setState({transmissionFluidLevelCondition  :  event.target.value})}
      ChangeHandlerTransmissionShiftPoints            = (event) =>  { this.setState({transmissionShiftPoints          :  event.target.value})}
      ChangeHandlerTransmissionSlippingBinding        = (event) =>  { this.setState({transmissionSlippingBinding      :  event.target.value})}
      ChangeHandlerTransmissionNoise                  = (event) =>  { this.setState({transmissionNoise                :  event.target.value})}
      ChangeHandlerTransmissionMounts                 = (event) =>  { this.setState({transmissionMounts               :  event.target.value})}
      ChangeHandlerTransmissionFluidLeaks             = (event) =>  { this.setState({transmissionFluidLeaks           :  event.target.value})}


      ChangeHandlerBrakeFluidLeaksLinesFiltering      = (event) =>  { this.setState({brakeFluidLeaksLinesFiltering    :  event.target.value})}
      ChangeHandlerBrakeMasterCylinder                = (event) =>  { this.setState({brakeMasterCylinder              :  event.target.value})}
      ChangeHandlerBrakePumpBooster                   = (event) =>  { this.setState({brakePumpBooster                 :  event.target.value})}
      ChangeHandlerBrakeLiningsFronts                 = (event) =>  { this.setState({brakeLiningsFronts               :  event.target.value})}
      ChangeHandlerBrakeLiningsRears                  = (event) =>  { this.setState({brakeLiningsRears                :  event.target.value})}
      ChangeHandlerTreadLeftFront                     = (event) =>  { this.setState({treadLeftFront                   :  event.target.value})}
      ChangeHandlerTreadLeftRear                      = (event) =>  { this.setState({treadLeftRear                    :  event.target.value})}

      ChangeHandlerEnginePerformance                  = (event) =>  { this.setState({enginePerformance                :  event.target.value})}
      ChangeHandlerEngineNoise                        = (event) =>  { this.setState({engineNoise                      :  event.target.value})}
      ChangeHandlerEngineOilPressureLightGauge        = (event) =>  { this.setState({engineOilPressureLightGauge      :  event.target.value})}
      ChangeHandlerEngineMotorMounts                  = (event) =>  { this.setState({engineMotorMounts                :  event.target.value})}
      ChangeHandlerEngineExhaustLeak                  = (event) =>  { this.setState({engineExhaustLeak                :  event.target.value})}
      ChangeHandlerEngineOilLevelCondition            = (event) =>  { this.setState({engineOilLevelCondition          :  event.target.value})}
      ChangeHandlerEngineOilLeaks                     = (event) =>  { this.setState({engineOilLeaks                   :  event.target.value})}
      ChangeHandlerEngineOilSmoke                     = (event) =>  { this.setState({engineOilSmoke                   :  event.target.value})}

      ChangeHandlerDriveVibration                     = (event) =>  { this.setState({driveVibration                    :  event.target.value})}
      ChangeHandlerDriveNoise                         = (event) =>  { this.setState({driveNoise                        :  event.target.value})}
      ChangeHandlerDriveFluidLeaks                    = (event) =>  { this.setState({driveFluidLeaks                   :  event.target.value})}
      ChangeHandlerDriveCVUJoints                     = (event) =>  { this.setState({driveCVUJoints                    :  event.target.value})}

      ChangeHandlerSuspensionControlHarms             = (event) =>  { this.setState({suspensionControlHarms            :  event.target.value})}
      ChangeHandlerSuspensionBallJoints               = (event) =>  { this.setState({suspensionBallJoints              :  event.target.value})}
      ChangeHandlerSuspensionWheelBearings            = (event) =>  { this.setState({suspensionWheelBearings           :  event.target.value})}
      ChangeHandlerSuspensionTorsionBarsSprings       = (event) =>  { this.setState({suspensionTorsionBarsSprings      :  event.target.value})}
      ChangeHandlerSuspensionStabilizerShaftLinks     = (event) =>  { this.setState({suspensionStabilizerShaftLinks    :  event.target.value})}
      ChangeHandlerSuspensionMacPhersonStruts         = (event) =>  { this.setState({suspensionMacPhersonStruts        :  event.target.value})}

      ChangeHandlerSteeringFluidLevelLeak             = (event) =>  { this.setState({steeringFluidLevelLeak            :  event.target.value})}
      ChangeHandlerSteeringPump                       = (event) =>  { this.setState({steeringPump                      :  event.target.value})}
      ChangeHandlerSteeringLinkage                    = (event) =>  { this.setState({steeringLinkage                   :  event.target.value})}
      ChangeHandlerSteeringRackPinionGearBox          = (event) =>  { this.setState({steeringRackPinionGearBox         :  event.target.value})}

      ChangeHandlerAirCompressorclutch                = (event) =>  { this.setState({airCompressorclutch               :  event.target.value})}
      ChangeHandlerAirCondensor                       = (event) =>  { this.setState({airCondensor                      :  event.target.value})}
      ChangeHandlerAirModeControl                     = (event) =>  { this.setState({airModeControl                    :  event.target.value})}

      ChangeHandlerElectricalChargingSystem           = (event) =>  { this.setState({electricalChargingSystem          :  event.target.value})}
      ChangeHandlerElectricalStarter                  = (event) =>  { this.setState({electricalStarter                 :  event.target.value})}
      ChangeHandlerElectricalPowerWindowMotor         = (event) =>  { this.setState({electricalPowerWindowMotor        :  event.target.value})}
      ChangeHandlerElectricalPowerSeatsMotor          = (event) =>  { this.setState({electricalPowerSeatsMotor         :  event.target.value})}
      ChangeHandlerElectricalHorn                     = (event) =>  { this.setState({electricalHorn                    :  event.target.value})}
      ChangeHandlerElectricalPowerLocks               = (event) =>  { this.setState({electricalPowerLocks              :  event.target.value})}
      ChangeHandlerElectricalPowerRoofMotor           = (event) =>  { this.setState({electricalPowerRoofMotor          :  event.target.value})}
      ChangeHandlerElectricalPowerAntenaAudio         = (event) =>  { this.setState({electricalPowerAntenaAudio        :  event.target.value})}
      ChangeHandlerElectricalCruiseControl            = (event) =>  { this.setState({electricalCruiseControl           :  event.target.value})}
      ChangeHandlerElectricalPowerMirrors             = (event) =>  { this.setState({electricalPowerMirrors            :  event.target.value})}
      ChangeHandlerElectricalRearDefrost              = (event) =>  { this.setState({electricalRearDefrost             :  event.target.value})}
      ChangeHandlerElectricalWiperMotorBlades         = (event) =>  { this.setState({electricalWiperMotorBlades        :  event.target.value})}
      ChangeHandlerElectricalLights                   = (event) =>  { this.setState({electricalLights                  :  event.target.value})}


      print(){
        window.print();
    }

    

      render(){
         function styles(item) {
            if(item === "NS") {
                return "red"
            } if(item === "2-4 mm"){
                return "red"
            } if(item === "4-6 mm"){
                return "red"
            } if(item === "2-4 / 32"){
                return "red"
            } if(item === "4-6 / 32"){
                return "red"
            }
            else {
                return "black"
            }
         }
    return(
        <Container>
            <form onSubmit={authentication.bind()} >
            <Row style={{marginTop:20}}>
                <Col className='text-center'>
                    <div>
                      <Link to='/account'>
                          <img style={{width:300, height:120}} src={logo} alt='...' />
                      </Link>
                    </div>
                </Col>
                <Col>
                <h5 className="text-center">{businessname}</h5>
                    <p className="text-center">{bstreet}<br/>
                    {bcity}<br/>{bphone}</p>
                </Col>
                <Col>
                <h5 className='text-center'>VEHICLE</h5>
                    <h5 className='text-center'>INSPECTION REPORT</h5>
                    <div className='text-right'>
                    <span>Date: </span> 
                    <input style={{width:'150px'}} type="date" id="Date" name="Date" />
                    </div>
                    <div className='text-right'>
                    <span>Inspection No: </span> 
                    <input style={{width:'150px'}} type="number" id="IdInspection" name="IdInspection" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{marginTop:10}}>
                <table className="table table-sm" style={{margin:0}}>                  
                    <tbody>
                      <tr>                        
                        <td style={{width:'100px'}}><TextField inputProps={{maxLength: 7}}  label='Lincense Plate No:'  id="Lincense" name='Lincense' type='text'  variant="standard" /></td>
                        <td style={{width:'100px'}}><Autocomplete
                              id="lYear"
                              options={dbData.year}
                              getOptionLabel={(option) => option.title}
                           
                              renderInput={(params) => <TextField {...params} id="Year" name="Year" type='text' label="Year: "  variant="standard" />}
                            /></td>
                        <td style={{width:'100px'}}><Autocomplete
                              id="lMake"
                              options={dbMakes.makes}
                              getOptionLabel={(option) => option.title}
                           
                              renderInput={(params) => <TextField {...params} id="Make" name="Make" type='text' label="Make: "  variant="standard" />}
                            /></td>
                        <td style={{width:'100px'}}><Autocomplete
                              id="lModel"
                              options={dbModels.models}
                              getOptionLabel={(option) => option.title}
                           
                              renderInput={(params) => <TextField {...params} id="Model" name="Model" type='text' label="Model: "  variant="standard" />}
                            /></td>                            
                        <td style={{width:'140px'}}><TextField inputProps={{ maxLength: 20}}  fullWidth label='VIN'  id="Vin" name='Vin' type='text'  variant="standard" onChange={() => {}} /></td>
                        <td style={{width:'100px'}}><TextField inputProps={{maxLength: 7}}  label='Odometer Reading'  id="Odometer" name='Odometer' type='text'  variant="standard" onChange={() => {}} /></td>
                                           
                      </tr>
                    </tbody>                                        
                    </table> 
                    <p style={{textAlign:'center', margin:0}}><strong>Note:</strong> This inspection is effective on the date of inspection and for vehicle mileage at inspection, as indicated above. 
                        Checked items under "Needs Service" may need service or repair, see technician's comments on reverse side.</p>                   
                    <table className="table  table-sm">                    
                    <tbody>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>1. Cooling & Fuel System</h6></td> 
                        <td style={{width:'80px'}}><span>OK / NS</span></td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>4. Engine</h6></td>                         
                         <td style={{width:'80px'}}><span>OK / NS</span></td>                        
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10,  fontWeight:'bold'}}>7. Steering</h6></td> 
                        <td style={{width:'80px'}}><span>OK / NS</span></td>
                      </tr>
                      
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Water Pump</h6></td>                         
                        <td style={{width:'80px'}}>                       
                        <select className="custom-select" style={{color: styles(this.state.coolingWaterPump)}} id="CoolingWaterPump"  onChange={this.ChangeHandlerCoolingWaterPump}  >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Engine Performance</h6></td>                         
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.enginePerformance)}} id="EnginePerformance" onChange={this.ChangeHandlerEnginePerformance}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Fluid Level / Leak</h6></td> 
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.steeringFluidLevelLeak)}} id="SteeringFluidLevelLeak" onChange={this.ChangeHandlerSteeringFluidLevelLeak}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr> 
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Engine Fan Motor</h6></td>                         
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.coolingEngineFanMotor)}} id="CoolingEngineFanMotor" onChange={this.ChangeHandlerCoolingEngineFanMotor}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                         <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Noise</h6></td> 
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.engineNoise)}} id="EngineNoise" onChange={this.ChangeHandlerEngineNoise}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Pump</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.steeringPump)}} id="SteeringPump" onChange={this.ChangeHandlerSteeringPump}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                      </tr> 
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Fan & Clutch/Shroud</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.coolingFanClutchShroud)}} id="CoolingFanClutchShroud" onChange={this.ChangeHandlerCoolingFanClutchShroud}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Oil Pressure Light/Gauge</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.engineOilPressureLightGauge)}} id="EngineOilPressureLightGauge" onChange={this.ChangeHandlerEngineOilPressureLightGauge}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Linkage</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.steeringLinkage)}} id="SteeringLinkage" onChange={this.ChangeHandlerSteeringLinkage}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Coolant Recovery Unit</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.coolingCoolantRecoveryUnit)}} id="CoolingCoolantRecoveryUnit" onChange={this.ChangeHandlerCoolingCoolantRecoveryUnit}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Motor Mounts</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.engineMotorMounts)}} id="EngineMotorMounts" onChange={this.ChangeHandlerEngineMotorMounts}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Rack & Pinion/Gear Box</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.steeringRackPinionGearBox)}} id="SteeringRackPinionGearBox" onChange={this.ChangeHandlerSteeringRackPinionGearBox}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select> 
                            </td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>E. Belts & Hoses</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.coolingBeltsHoses)}} id="CoolingBeltsHoses" onChange={this.ChangeHandlerCoolingBeltsHoses}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>E. Exhaust Leak</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.engineExhaustLeak)}} id="EngineExhaustLeak" onChange={this.ChangeHandlerEngineExhaustLeak} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>8. Air Conditioning</h6></td>
                        <td style={{width:'80px' }}></td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>F. Temperature Light/Gauge</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.coolingTemperatureLightGauge)}} id="CoolingTemperatureLightGauge" onChange={this.ChangeHandlerCoolingTemperatureLightGauge}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>F. Oil Level Condition</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.engineOilLevelCondition)}} id="EngineOilLevelCondition" onChange={this.ChangeHandlerEngineOilLevelCondition}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Compressor/clutch</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.airCompressorclutch)}} id="AirCompressorclutch" onChange={this.ChangeHandlerAirCompressorclutch}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>G. Fluid Leaks</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.coolingFluidLeaks)}} id="CoolingFluidLeaks" onChange={this.ChangeHandlerCoolingFluidLeaks}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>G. Oil Leaks</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.engineOilLeaks)}} id="EngineOilLeaks" onChange={this.ChangeHandlerEngineOilLeaks}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Condensor</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.airCondensor)}} id="AirCondensor" onChange={this.ChangeHandlerAirCondensor}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>H. Fuel Pump/Tank & Lines</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.coolingFuelPumpTankLines)}} id="CoolingFuelPumpTankLines" onChange={this.ChangeHandlerCoolingFuelPumpTankLines}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>H. Oil Smoke</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.engineOilSmoke)}} id="EngineOilSmoke" onChange={this.ChangeHandlerEngineOilSmoke}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Mode Control</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.airModeControl)}} id="AirModeControl" onChange={this.ChangeHandlerAirModeControl}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>I. Diesel Injectors & Pump</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.coolingDieselInjectorsPump)}} id="CoolingDieselInjectorsPump" onChange={this.ChangeHandlerCoolingDieselInjectorsPump}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>5. Drive Axle</h6></td>

                        
                        <td style={{width:'80px'}}></td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>9. Electrical System</h6></td>
                        <td style={{width:'80px' }}></td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>2. Transmission/Transfer Case</h6></td>
                        <td style={{width:'80px'}}></td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Vibration</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.driveVibration)}} id="DriveVibration" onChange={this.ChangeHandlerDriveVibration}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Charging System</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.electricalChargingSystem)}} id="ElectricalChargingSystem" onChange={this.ChangeHandlerElectricalChargingSystem}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Fluid Level/Condition</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.transmissionFluidLevelCondition)}} id="TransmissionFluidLevelCondition" onChange={this.ChangeHandlerTransmissionFluidLevelCondition}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Noise</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.driveNoise)}} id="DriveNoise" onChange={this.ChangeHandlerDriveNoise} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Starter</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.electricalStarter)}} id="ElectricalStarter" onChange={this.ChangeHandlerElectricalStarter} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Shift Points (if automatic)</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.transmissionShiftPoints)}} id="TransmissionShiftPoints" onChange={this.ChangeHandlerTransmissionShiftPoints} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Fluid Leaks</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.driveFluidLeaks)}} id="DriveFluidLeaks" onChange={this.ChangeHandlerDriveFluidLeaks} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Power Window Motor</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.electricalPowerWindowMotor)}} id="ElectricalPowerWindowMotor" onChange={this.ChangeHandlerElectricalPowerWindowMotor} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Slipping / Binding</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.transmissionSlippingBinding)}} id="TransmissionSlippingBinding" onChange={this.ChangeHandlerTransmissionSlippingBinding} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. CV/U Joints</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.driveCVUJoints)}} id="DriveCVUJoints" onChange={this.ChangeHandlerDriveCVUJoints} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Power Seats Motor/H</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.electricalPowerSeatsMotor)}} id="ElectricalPowerSeatsMotor" onChange={this.ChangeHandlerElectricalPowerSeatsMotor} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Noise</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.transmissionNoise)}} id="TransmissionNoise" onChange={this.ChangeHandlerTransmissionNoise} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>6. Suspension</h6></td>
                        
                        <td style={{width:'80px'}}></td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>E. Horn</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.electricalHorn)}} id="ElectricalHorn" onChange={this.ChangeHandlerElectricalHorn} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>E. Transmission Mounts</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.transmissionMounts)}} id="TransmissionMounts" onChange={this.ChangeHandlerTransmissionMounts} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select> </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Control Harms</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.suspensionControlHarms)}} id="SuspensionControlHarms" onChange={this.ChangeHandlerSuspensionControlHarms} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>F. Power Locks</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(this.state.electricalPowerLocks)}} id="ElectricalPowerLocks" onChange={this.ChangeHandlerElectricalPowerLocks} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>F. Fluid Leaks</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.transmissionFluidLeaks)}} id="TransmissionFluidLeaks" onChange={this.ChangeHandlerTransmissionFluidLeaks} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Ball Joints</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(this.state.suspensionBallJoints)}} id="SuspensionBallJoints" onChange={this.ChangeHandlerSuspensionBallJoints} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>G. Power Roof Motor</h6></td>
                        <td style={{width:'80px' }}>
                            <select className="custom-select" style={{color: styles(this.state.electricalPowerRoofMotor)}} id="ElectricalPowerRoofMotor" onChange={this.ChangeHandlerElectricalPowerRoofMotor} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr > 
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>3. Brake System</h6></td>
                        <td style={{width:'80px'}}></td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Wheel Bearings</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.suspensionWheelBearings)}} id="SuspensionWheelBearings" onChange={this.ChangeHandlerSuspensionWheelBearings} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>H. Power Antena/Audio S.</h6></td>
                        <td style={{width:'80px' }}>
                            <select className="custom-select" style={{color: styles(this.state.electricalPowerAntenaAudio)}} id="ElectricalPowerAntenaAudio" onChange={this.ChangeHandlerElectricalPowerAntenaAudio} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select> 
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Fluid/Leaks/Lines/Filtering</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.brakeFluidLeaksLinesFiltering)}} id="BrakeFluidLeaksLinesFiltering" onChange={this.ChangeHandlerBrakeFluidLeaksLinesFiltering} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Torsion Bars/Springs</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.suspensionTorsionBarsSprings)}} id="SuspensionTorsionBarsSprings" onChange={this.ChangeHandlerSuspensionTorsionBarsSprings} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select> 
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>I. Cruise Control</h6></td>
                        <td style={{width:'80px' }}>
                            <select className="custom-select" style={{color: styles(this.state.electricalCruiseControl)}} id="ElectricalCruiseControl" onChange={this.ChangeHandlerElectricalCruiseControl} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Master Cylinder</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.brakeMasterCylinder)}} id="BrakeMasterCylinder" onChange={this.ChangeHandlerBrakeMasterCylinder}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>E. Stabilizer Shaft & Links</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.suspensionStabilizerShaftLinks)}} id="SuspensionStabilizerShaftLinks" onChange={this.ChangeHandlerSuspensionStabilizerShaftLinks}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>J. Power Mirrors</h6></td>
                        <td style={{width:'80px' }}>
                            <select className="custom-select" style={{color: styles(this.state.electricalPowerMirrors)}} id="ElectricalPowerMirrors" onChange={this.ChangeHandlerElectricalPowerMirrors}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr>
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Pump/Booster</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.brakePumpBooster)}} id="BrakePumpBooster" onChange={this.ChangeHandlerBrakePumpBooster}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>F. MacPherson Struts</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.suspensionMacPhersonStruts)}} id="SuspensionMacPhersonStruts" onChange={this.ChangeHandlerSuspensionMacPhersonStruts}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>K. Rear Defrost</h6></td>
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.electricalRearDefrost)}} id="ElectricalRearDefrost" onChange={this.ChangeHandlerElectricalRearDefrost}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr> 
                      <tr>
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>Brake Linings Fronts</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.brakeLiningsFronts)}} id="BrakeLiningsFronts" onChange={this.ChangeHandlerBrakeLiningsFronts}>
                                <option value=""></option>
                                <option value="2-4 mm">2-4 mm</option>
                                <option value="4-6 mm">4-6 mm</option>
                                <option value="6-8 mm">6-8 mm</option>
                                <option value="8-10 mm">8-10 mm</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>Brake Linings Rears</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.brakeLiningsRears)}} id="BrakeLiningsRears"  onChange={this.ChangeHandlerBrakeLiningsRears} >
                                <option value=""></option>
                                <option value="2-4 mm">2-4 mm</option>
                                <option value="4-6 mm">4-6 mm</option>
                                <option value="6-8 mm">6-8 mm</option>
                                <option value="8-10 mm">8-10 mm</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>L. Wiper Motor/Blades</h6></td>
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.electricalWiperMotorBlades)}} id="ElectricalWiperMotorBlades"  onChange={this.ChangeHandlerElectricalWiperMotorBlades} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>  
                      <tr>
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>Tread Left-Front</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.treadLeftFront)}} id="TreadLeftFront" onChange={this.ChangeHandlerTreadLeftFront} >
                                <option value=""></option>
                                <option value="2-4 / 32">2-4 / 32</option>
                                <option value="4-6 / 32">4-6 / 32</option>
                                <option value="6-8 / 32">6-8 / 32</option>
                                <option value="8-10 / 32">8-10 / 32</option>
                                <option value="10-12 / 32">10-12 / 32</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>Tread Left-Rear</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.treadLeftRear)}} id="TreadLeftRear" onChange={this.ChangeHandlerTreadLeftRear}>
                                <option value=""></option>
                                <option value="2-4 / 32">2-4 / 32</option>
                                <option value="4-6 / 32">4-6 / 32</option>
                                <option value="6-8 / 32">6-8 / 32</option>
                                <option value="8-10 / 32">8-10 / 32</option>
                                <option value="10-12 / 32">10-12 / 32</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>M. Lights</h6></td>
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(this.state.electricalLights)}} id="ElectricalLights" onChange={this.ChangeHandlerElectricalLights}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>            
                    </tbody>
                    </table>
                </Col>
            </Row>
            <div className="text-center" style={{marginBottom:"50px"}}><span>1 - 2</span></div>            
        
        <Row className='mb-5' style={{marginTop:"150px"}}>           
            <Col md={12} className='text-left'>
                <h6>MECHANICS COMMENTS:</h6>                       
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">System</th>
                          <th scope="col" className="text-center">Detail</th>
                        </tr>
                      </thead>
                      <tbody>
                        { this.state.coolingWaterPump               === "NS" && <tr><td>Cooling & Fuel System</td><td>Water Pump</td></tr> }
                        { this.state.coolingEngineFanMotor          === "NS" && <tr><td>Cooling & Fuel System</td><td>Engine Fan Motor</td></tr> }
                        { this.state.coolingFanClutchShroud         === "NS" && <tr><td>Cooling & Fuel System</td><td>Fan Clutch Shroud</td></tr> }
                        { this.state.coolingCoolantRecoveryUnit     === "NS" && <tr><td>Cooling & Fuel System</td><td>Coolant Recovery Unit</td></tr> }
                        { this.state.coolingBeltsHoses              === "NS" && <tr><td>Cooling & Fuel System</td><td>Belts Hoses</td></tr> }
                        { this.state.coolingTemperatureLightGauge   === "NS" && <tr><td>Cooling & Fuel System</td><td>Temperature Light Gauge</td></tr> }
                        { this.state.coolingFluidLeaks              === "NS" && <tr><td>Cooling & Fuel System</td><td>Fluid Leaks</td></tr> }
                        { this.state.coolingFuelPumpTankLines       === "NS" && <tr><td>Cooling & Fuel System</td><td>Fuel Pump Tank Lines</td></tr> }
                        { this.state.coolingDieselInjectorsPump     === "NS" && <tr><td>Cooling & Fuel System</td><td>Diesel Injectors Pump</td></tr> }


                        { this.state.transmissionFluidLevelCondition === "NS" && <tr><td>Transmission / Transfer Case</td><td>Fluid Level Condition</td></tr> }
                        { this.state.transmissionShiftPoints         === "NS" && <tr><td>Transmission / Transfer Case</td><td>Shift Points         </td></tr> }
                        { this.state.transmissionSlippingBinding     === "NS" && <tr><td>Transmission / Transfer Case</td><td>Slipping Binding     </td></tr> }
                        { this.state.transmissionNoise               === "NS" && <tr><td>Transmission / Transfer Case</td><td>Noise                </td></tr> }
                        { this.state.transmissionMounts              === "NS" && <tr><td>Transmission / Transfer Case</td><td>Mounts               </td></tr> }
                        { this.state.transmissionFluidLeaks          === "NS" && <tr><td>Transmission / Transfer Case</td><td>Fluid Leaks          </td></tr> }


                        { this.state.brakeFluidLeaksLinesFiltering   === "NS" && <tr><td>Brake System</td><td>Fluid Leaks Lines Filtering </td></tr> }
                        { this.state.brakeMasterCylinder             === "NS" && <tr><td>Brake System</td><td>Master Cylinder             </td></tr> }
                        { this.state.brakePumpBooster                === "NS" && <tr><td>Brake System</td><td>Pump Booster                </td></tr> }
                        { this.state.brakeLiningsFronts === "2-4 mm" && <tr><td>Brake System</td><td>Linings Fronts {this.state.brakeLiningsFronts} </td></tr> }
                        { this.state.brakeLiningsFronts === "4-6 mm" && <tr><td>Brake System</td><td>Linings Fronts {this.state.brakeLiningsFronts} </td></tr> }
                        { this.state.brakeLiningsRears  === "2-4 mm" && <tr><td>Brake System</td><td>Linings Rears {this.state.brakeLiningsRears}  </td></tr> }
                        { this.state.brakeLiningsRears  === "4-6 mm" && <tr><td>Brake System</td><td>Linings Rears {this.state.brakeLiningsRears}  </td></tr> }
                        { this.state.treadLeftFront     === "2-4 / 32"  && <tr><td>Brake System</td><td>Tread Left Front {this.state.treadLeftFront}  </td></tr> }
                        { this.state.treadLeftFront     === "4-6 / 32"  && <tr><td>Brake System</td><td>Tread Left Front {this.state.treadLeftFront}  </td></tr> }
                        { this.state.treadLeftRear      === "2-4 / 32"  && <tr><td>Brake System</td><td>TreadLeft Rear {this.state.treadLeftRear}  </td></tr> }
                        { this.state.treadLeftRear      === "4-6 / 32"  && <tr><td>Brake System</td><td>Tread Left Rear {this.state.treadLeftRear}   </td></tr> }


                        { this.state.enginePerformance               === "NS" && <tr><td>Engine</td><td>Performance               </td></tr> }
                        { this.state.engineNoise                     === "NS" && <tr><td>Engine</td><td>Noise                     </td></tr> }
                        { this.state.engineOilPressureLightGauge     === "NS" && <tr><td>Engine</td><td>Oil Pressure light Gauge   </td></tr> }
                        { this.state.engineMotorMounts               === "NS" && <tr><td>Engine</td><td>Motor Mounts              </td></tr> }
                        { this.state.engineExhaustLeak               === "NS" && <tr><td>Engine</td><td>Exhaust Leak              </td></tr> }
                        { this.state.engineOilLevelCondition         === "NS" && <tr><td>Engine</td><td>Oil Level Condition       </td></tr> }
                        { this.state.engineOilLeaks                  === "NS" && <tr><td>Engine</td><td>Oil Leaks                 </td></tr> }
                        { this.state.engineOilSmoke                  === "NS" && <tr><td>Engine</td><td>Oil Smoke                 </td></tr> }


                        { this.state.driveVibration                   === "NS" && <tr><td>Drive Axle</td><td>Vibration   </td></tr> }
                        { this.state.driveNoise                       === "NS" && <tr><td>Drive Axle</td><td>Noise       </td></tr> }
                        { this.state.driveFluidLeaks                  === "NS" && <tr><td>Drive Axle</td><td>Fluid Leaks </td></tr> }
                        { this.state.driveCVUJoints                   === "NS" && <tr><td>Drive Axle</td><td>CV/U Joints </td></tr> }

                        { this.state.suspensionControlHarms           === "NS" && <tr><td>Suspension</td><td>Control Harms          </td></tr> }
                        { this.state.suspensionBallJoints             === "NS" && <tr><td>Suspension</td><td>Ball Joints            </td></tr> }
                        { this.state.suspensionWheelBearings          === "NS" && <tr><td>Suspension</td><td>Wheel Bearings         </td></tr> }
                        { this.state.suspensionTorsionBarsSprings     === "NS" && <tr><td>Suspension</td><td>Torsion Bars Springs   </td></tr> }
                        { this.state.suspensionStabilizerShaftLinks   === "NS" && <tr><td>Suspension</td><td>Stabilizer Shaft Links </td></tr> }
                        { this.state.suspensionMacPhersonStruts       === "NS" && <tr><td>Suspension</td><td>MacPherson Struts      </td></tr> }


                        { this.state.steeringFluidLevelLeak          === "NS" && <tr><td>Steering</td><td>Fluid Level Leak     </td></tr> }
                        { this.state.steeringPump                    === "NS" && <tr><td>Steering</td><td>Pump                 </td></tr> }
                        { this.state.steeringLinkage                 === "NS" && <tr><td>Steering</td><td>Linkage              </td></tr> }
                        { this.state.steeringRackPinionGearBox       === "NS" && <tr><td>Steering</td><td>Rack Pinion Gear Box </td></tr> }


                        { this.state.airCompressorclutch      === "NS" && <tr><td>Air Conditioning</td><td>Compressor clutch  </td></tr> }
                        { this.state.airCondensor             === "NS" && <tr><td>Air Conditioning</td><td>Condensor          </td></tr> }
                        { this.state.airModeControl           === "NS" && <tr><td>Air Conditioning</td><td>Mode Control       </td></tr> }


                        { this.state.electricalChargingSystem     === "NS" && <tr><td>Electrical System</td><td>Charging System  </td></tr> }
                        { this.state.electricalStarter            === "NS" && <tr><td>Electrical System</td><td>Starter         </td></tr> }
                        { this.state.electricalPowerWindowMotor   === "NS" && <tr><td>Electrical System</td><td>Power Window Motor</td></tr> }
                        { this.state.electricalPowerSeatsMotor    === "NS" && <tr><td>Electrical System</td><td>Power Seats Motor </td></tr> }
                        { this.state.electricalHorn               === "NS" && <tr><td>Electrical System</td><td>Horn            </td></tr> }
                        { this.state.electricalPowerLocks         === "NS" && <tr><td>Electrical System</td><td>Power Locks      </td></tr> }
                        { this.state.electricalPowerRoofMotor     === "NS" && <tr><td>Electrical System</td><td>Power Roof Motor  </td></tr> }
                        { this.state.electricalPowerAntenaAudio   === "NS" && <tr><td>Electrical System</td><td>Power Antena Audio</td></tr> }
                        { this.state.electricalCruiseControl      === "NS" && <tr><td>Electrical System</td><td>Cruise Control   </td></tr> }
                        { this.state.electricalPowerMirrors       === "NS" && <tr><td>Electrical System</td><td>Power Mirrors    </td></tr> }
                        { this.state.electricalRearDefrost        === "NS" && <tr><td>Electrical System</td><td>Rear Defrost     </td></tr> }
                        { this.state.electricalWiperMotorBlades   === "NS" && <tr><td>Electrical System</td><td>Wiper Motor Blades</td></tr> }
                        { this.state.electricalLights             === "NS" && <tr><td>Electrical System</td><td>Lights          </td></tr> }


                      </tbody>
                     </table>
                    
                    <span>Comments:</span>
                    <textarea  
                      style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                       id="Comments" 
                       name="Comments" 
                       type='text' 
                    />               
                  
            </Col>
        </Row> 
        <Row>
            <Col md={4} className='text-center' >
                <span><strong>{bemail}</strong></span>
            </Col>
            <Col md={4} className='text-center'>
                <h5 ><strong>Thanks for choosing us.</strong></h5>
            </Col>
            <Col md={4} >
            <Row style={{marginBottom:25}}>
                <Col>
                   <Button                                
                        type="submit"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}                  
                        >
                         Save
                    </Button>
                </Col>
                <Col>
                    <Button    
                           onClick={this.print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
            </Col>
        </Row>
        </form>
        </Container>
    )
}
}


export default Inspection
