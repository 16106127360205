import React from "react"
import { FirebaseDatabaseProvider } from "@react-firebase/database";
import { FirebaseDatabaseNode } from '@react-firebase/database'
import firebase from "firebase";
import {firebaseConfig} from "../auth/firebase";
import { TextField } from '@material-ui/core';


class Getidinvoicetire extends React.Component {
  constructor(props) {
    super(props);
    this.state = { username: '' };
  }
  myChangeHandler = (event) => {
    this.setState({username: event.target.value});
  }

  
  render() {
    return (  
      <FirebaseDatabaseProvider firebase={firebase} {...firebaseConfig}>    
      <FirebaseDatabaseNode path="/invoicestire/-MqYj-tbBSiNFugKgmre/-MqYjnPvUaRkK-3MP2re/-MqYhaUrVqrGgYskeYre" limitToLast={1}  >
        {({ value }) => {
          if (value === null || typeof value === "undefined") return null;
          //const keys = Object.keys(value);
          const values = Object.values(value); 
          const idinvoicelast = parseInt(values[0].Idinvoice) + 1 ;
          return (
            // <h4  id="Idinvoice" name="Idinvoice">{idinvoicelast ? idinvoicelast : 1000} </h4>   
            <TextField InputProps={{ disableUnderline: true }} id="Idinvoice" name="Idinvoice" type='number'  variant="standard" value={idinvoicelast} /> 
            // <h5 className='text-left'>{idinvoicelast}</h5>     
          );
        }}
      </FirebaseDatabaseNode>           
      </FirebaseDatabaseProvider>      
    );
  }
}

export default Getidinvoicetire