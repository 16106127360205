import React from 'react'
import {Container, Row, Col, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Booking = () => {
    return(
        <Container style={{marginTop:25}}>
            <Row>
                <Col>
                <h4 >
                    We are working in this page.
                </h4>
                <div>
                    <img src="./astronaut.png" alt='...' />
                </div>
               
                <div >
                   <Link to="/">                       
                    <Button as={Col} variant="primary">Return Home</Button>
                    </Link>
                </div>
                    
                </Col>
            </Row>        
      </Container>

    )
}
export default Booking

// import React, { useState } from 'react'
// import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
// import { Container, Row, Col, Button, Modal} from 'react-bootstrap'
// import { Link } from 'react-router-dom'
// import TextField from '@material-ui/core/TextField';
// import "./Booking.css"
// import format from 'date-fns/format'
// import parse from 'date-fns/parse'
// import startOfWeek from 'date-fns/startOfWeek'
// import getDay from 'date-fns/getDay'
// import enUS from 'date-fns/locale/en-US'
// import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import { useSelector} from 'react-redux'


// const locales = {
//   'en-US': enUS,
// }

// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek,
//   getDay,
//   locales,
// })

// // const events = [
// //     {
// //         start: "12/5/2021",
// //         end: "12/5/2021",
// //         title: "Some title"
// //       },
// //       {
// //         start: "12/8/2021",
// //         end: "12/8/2021",
// //         title: "Some title"
// //       },
// //       {
// //         start: "12/15/2021",
// //         end: "12/15/2021",
// //         title: "Some title"
// //       },
// //       {
// //         start: "12/15/2021",
// //         end: "12/15/2021",
// //         title: "Some title"
// //       }
// // ]

// function MyVerticallyCenteredModal(props) {

//     const [start, setStart] = useState('2021/12/12')
//     const [end, setEnd] = useState('2021/12/12')
//     const [title, setTitle] = useState('')

//     const [addEvent, setAddEvent] = useState({})


//     // console.log(addEvent)
//     //function submitHandler() {        
//         // const objStart = start.target.value
//         // const valorStart = objStart.replace(/-/g, "/")
//         // const objEnd = end.target.value
//         // const valorEnd = objEnd.replace(/-/g, "/")
//         // const valorTitle = title.target.value

//         // setAddEvent({
//         //     start: valorStart,
//         //     end: valorEnd,
//         //     title: valorTitle
//        // })
//         //props.onHide()
//     //}

//     return (
//       <Modal
//         {...props}
//         size="lg"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter">
//             Create new event
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//             <form>             
//                  <div >
//                  <TextField style={{ marginRight:25, fontSize:25}}   id="start" name='start' type='date'  variant="standard" onChange={(text) => setStart(text)} />
//                  <TextField style={{ fontSize:25}}   id="end" name='end' type='date'  variant="standard"  onChange={(text) => setEnd(text)} />
//                  </div>      
//                  <div style={{marginTop:10}}>
//                  <TextField style={{ fontSize:25}} fullWidth label='description'  id="title" name='title' type='text'  variant="standard" onChange={(text) => setTitle(text)} />
//                  </div>
              
//             </form>

//         </Modal.Body>
//         <Modal.Footer>
//           {/* <Button onClick={submitHandler}>send</Button> */}
//           <Button onClick={props.onHide}>Close</Button>
//         </Modal.Footer>
//       </Modal>
//     );
//   }


// const Booking = () =>  {

    
//     const [modalShow, setModalShow] = useState(false);

//     const [dataEvents, setDataEvents] = useState([])
//     const events = useSelector(state => state.events.events)

//     function handlerEvent() {
//         console.log('textt')
//     }
 

//     return(
//         <Container>
//             <Row style={{marginTop:25, marginBottom:25}}>
//                 <Col style={{textAlign:'center'}}>
//                     <Link to='/account'>
//                         <img               
//                         style={{width: '300px', height:150}}
//                         src="logo-black-500x310.jpg"
//                         alt='..'  />
//                     </Link>
//                 </Col>
//                 <Col style={{textAlign:'center'}}>
//                 <Button variant="primary" onClick={() => setModalShow(true)}>
//                       Create Event
//                     </Button>

//                     <MyVerticallyCenteredModal
//                       show={modalShow}
//                       onHide={() => setModalShow(false)}
//                     />
//                 </Col>
//                 <Col style={{textAlign:'center'}}>3</Col>
//             </Row>
//             <Calendar id='mycalendar' className='react-calendar'
//               onSelectEvent={handlerEvent}
//               localizer={localizer}
//               defaultDate={new Date()}
//               defaultView="month"
//               events={events}
//               style={{ height: "100vh" }}
//             />
//         </Container>
//     )   
    
// }
// export default Booking


// // class Booking extends Component {
// //     state = {
// //         events: [
// //           {
// //             start: "12/5/2021",
// //             end: "12/5/2021",
// //             title: "Some title"
// //           },
// //           {
// //             start: "12/8/2021",
// //             end: "12/8/2021",
// //             title: "Some title"
// //           },
// //           {
// //             start: "12/15/2021",
// //             end: "12/15/2021",
// //             title: "Some title"
// //           }
// //         ]
// //       };
    
// //       render() {
// //         return (
// //           <div>
// //             <Calendar className='react-calendar'
// //               localizer={localizer}
// //               defaultDate={new Date()}
// //               defaultView="month"
// //               events={this.state.events}
// //               style={{ height: "100vh" }}
// //             />
// //           </div>
// //         );
// //       }
// //     }

// // export default Booking;


