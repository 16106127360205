import { SET_EXPENSES } from "../actions/balance";

const initialState = {
    expenses: [],
}
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_EXPENSES:
           return {
               ...state,
               expenses: action.expenses
           }           

           default:
            break;
    }
    return state
}
