import { SET_PROFILES, GET_PAY_ID } from "../actions/profile";

const initialState = {
    profiles: [],
    getpayidload:''
}
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILES:
           return {
               ...state,
               profiles: action.profiles
           } 
        case GET_PAY_ID:       
           return {
             ...state,
             getpayidload: action.getpayidload
           }       
           default:
            break;
    }
    return state
}





