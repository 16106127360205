class Detail {
    constructor(id, idate, checkin, checkinbrake, checkout, checkoutbrake, active ) {     
        this.id = id;   
        this.idate = idate;
        this.checkin = checkin;
        this.checkinbrake = checkinbrake;
        this.checkout = checkout;
        this.checkoutbrake = checkoutbrake;
        this.active = active;
    }
  }
  
  export default Detail;
  