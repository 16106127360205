
class Profile {
    constructor(id, name, lastName, license, phone, email, address, idate, sdate, active, details ) {     
        this.id = id;   
        this.name = name;
        this.lastName = lastName;
        this.license = license;
        this.phone = phone;
        this.email = email;
        this.address = address;
        this.idate = idate;
        this.sdate = sdate;
        this.active = active;
        this.details = details;
    }
  }
  
  export default Profile;
  