import { SET_ESTIMATES } from "../actions/estimate";

const initialState = {
    estimates: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ESTIMATES:
           return {
               ...state,
               estimates: action.estimates
           }     
     
           default:
            break;
    }
    return state
}
