import React from 'react'
import {Container, Row, Col, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Error404 = () => {
    return(
        <Container style={{marginTop:25}}>
            <Row>
                <Col>
                <h4 >
                    Opps! Page not found
                </h4>
                <div>
                    <img src="./astronaut.png" alt='...' />
                </div>
               
                <div >
                   <Link to="/">                       
                    <Button as={Col} variant="primary">Return Home</Button>
                    </Link>
                </div>
                    
                </Col>
            </Row>        
      </Container>

    )
}
export default Error404