import React, {useState, useEffect} from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function DetailProfile(props) {

    const id = props.idhandler 

    let f = new Date();
    let fecha = f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear();
    let fechasemana = f.getDate() - 7 + "/" + (f.getMonth() +1) + "/" + f.getFullYear();
    // let fechatwoweeks = f.getDate() - 14 + "/" + (f.getMonth() +1) + "/" + f.getFullYear();

    const [pay, setPay] = useState(10)

    const profiles = useSelector(state => state.profiles.profiles)
    
    const [profile, setProfile] = useState('')

    
   
    useEffect(() => {
        if(profiles.length >= 1){
            let readProfile = profiles.filter(x => x.id === id && Object.values(x.details).find(s => s.active === false && s.idate >= fechasemana ))
           
        setProfile(readProfile)    
    } else {
        setProfile("")
    }
    }, [id, profiles, fechasemana])



    function payHandler(e) {
      setPay(e.target.value)
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={true}
      >        
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detail Profile  { profile.length >= 1 ?  profile[0].name : 'No Tiene Horas'}            
          </Modal.Title>
          <div className='mb-3'>
              <Button variant="outlined" >one week</Button>{' '}  
              <Button variant="outlined" >two weeks</Button>{' '}   
              <Button variant="outlined">gerenator</Button>{' '} 
            </div>           
        </Modal.Header>
        
            <Modal.Body>              
            <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Dates</th>
                    <th scope="col">Hours</th>
                    <th scope="col">Pay by Hour: $ <TextField  id="idate" name="idate" type='number'  variant="standard"  value={pay} onChange={payHandler} /></th>
                    

                  </tr>
                </thead>
                <tbody>
                {
                    profile &&
                         profile.map(item => (
                       <React.Fragment key={item.id}>
                            {
                                item.details.filter(s => s.active === false && s.idate >= fechasemana && s.idate <= fecha).map(x => (
                                    <tr key={x.id}>
                                        <td><TextField  id="idate" name="idate" type='text'  variant="standard" readOnly value={x.idate}  /></td>
                                        <td><TextField  id="hour" name="hour" type='text' readOnly value={((x.checkout.replace(/:/g, "") - x.checkin.replace(/:/g, "")) - (x.checkoutbrake.replace(/:/g, "") - x.checkinbrake.replace(/:/g, ""))) / 100}   variant="standard" /></td>
                                        <td><TextField  id="payment" name="payment" type='text' readOnly value={((((x.checkout.replace(/:/g, "") - x.checkin.replace(/:/g, "")) - (x.checkoutbrake.replace(/:/g, "") - x.checkinbrake.replace(/:/g, ""))) / 100).toFixed(2).toString().split('.').map(f => parseInt(f)).reduce((x, y) => ((x * 60 + y) / 60) * pay)).toFixed(2) }   variant="standard" /></td>
                                      </tr>                                    
                                ))
                                }
                        </React.Fragment>
                      ))
                  }                 
                  </tbody>
            </table>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    );
  }

  export default DetailProfile
