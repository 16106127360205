import React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import {Link } from 'react-router-dom'
import * as profilesActions from '../../store/actions/profile'
import {useDispatch } from 'react-redux'

function Generator(props) {

    const id = props.idhandler 
    const dispatch = useDispatch()  



    function cashHandler() {
        dispatch(profilesActions.getpayId(id))
        
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered         
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             Generator 
          </Modal.Title>
        </Modal.Header>
          <Modal.Body>
        <Form>
            <Row className="mb-3">
                <Col className='text-center'>
                    <Link to={'profiles/cashpayroll'} onClick={cashHandler}  >
                     <Button variant="outline-info" type="button" >
                       Payroll cash
                     </Button>  
                   </Link>                  

                </Col>
                <Col className='text-center'>
                    <Button variant="outline-info" type="button" onClick={cashHandler}>
                        Payroll check
                    </Button>
                </Col>
                <Col className='text-center'>
                    <Button variant="outline-info" type="button" onClick={cashHandler}>
                        Other
                    </Button>                
                </Col>               
            </Row>                
            </Form>
        </Modal.Body>         
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    );
  }

  export default Generator
