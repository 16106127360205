import React, {useState, useEffect} from "react";
import {Container, Modal, Button, Row, Col } from "react-bootstrap";
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import {businessname, bstreet, bcity, bphone } from '../../models/European'
import logo from '../../assets/image/logo.png'
import './CashPay.css'

function CashPay(props) {
    let f = new Date();
    let fecha = f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear();   
    const randomNumber = Math.floor(Math.random() * (9990000 - 110000)) + 110000

    // console.log(randomNumber)
    const profiles = useSelector(state => state.profiles.profiles)
    const getpayId = useSelector(state => state.profiles.getpayidload)
    // const id = getpayId 
    const[profile, setProfile] = useState('')

    useEffect(() => {
        if(profiles.length >= 1 && getpayId.length >= 1){
            let readProfile = profiles.find(x => x.id === getpayId)           
        setProfile(readProfile)    
    } else {
        setProfile("")
    }
    }, [getpayId, profiles])


        
    const [show, setShow] = useState(false);
    
    const [isChecked, setIsChecked] = useState(true);
    const [isCheckedlabor, setIsCheckedlabor] = useState(false);
    const [forpaymentof, setForpaymentof] = useState('')
    const [hours, setHours] = useState('0')
    const [rate, setRate] = useState('0')
    const [labor, setLabor] = useState('0')



    const handleOnChange = () => {
        setIsChecked(!isChecked);
        if(!isChecked === false){
            setHours('0')
            setRate('0')
        } 
      }

      const laborhandleOnChange = () => {
        setIsCheckedlabor(!isCheckedlabor);
        if(!isCheckedlabor === false){
            setLabor('0')
        } 
      }
      function forpaymentofHandler(e) {
        setForpaymentof(e.target.value)
      }


    function hoursHandler(e) {
        if(isChecked === false){
            setHours('0')
        } else {
            setHours(e.target.value)
        }
    }
    function rateHandler(e) {
        if(isChecked === false){
            setRate('0')
        } else {
            setRate(e.target.value)
        }
        
    }
    function laborHandler(e) {
        if(isCheckedlabor === false){
            setLabor('0')
        } else {
            setLabor(e.target.value)
        }
        
    }

    function resetHours() {
        setHours('0')
    }

    function resetLabor() {
        setLabor('0')
    }
    
    function print(){
      window.print();
  }
    return (
        <Container>
             <Row className='mb-3'>
            <Col sm={3} className='text-center'> 
            <div >
                <Link to='/account/profiles'>
                 <img               
                      style={{width: '300px', height:150}}
                      src={logo}
                     alt='..'  /> 
                </Link>
            </div>
             </Col>
            <Col sm={6} className="text-center" > 
            <h2 className="text-center">{businessname}</h2>
                <p className="text-center">{bstreet}<br/>
                {bcity}<br/>{bphone}</p>
            </Col>
            <Col sm={3} className="text-center"> 
                <Button variant="outline-info" onClick={() => setShow(true)}>
                 Cash Payment
                 </Button>
                  <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogclass="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title">
                        Fill up form
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-3'>
                        <h6>For Payment Of:</h6>
                        <TextField multiline fullWidth  inputProps={{ maxLength: 143 }} id="forpaymentof"  name="forpaymentof" type='text' label="" variant="standard" value={forpaymentof} onChange={forpaymentofHandler} />
                        </div>
                        <h6>Payment by Hour and Rate</h6>
                        <input
                           type="checkbox"
                           id="myCheckbox"
                           name="myCheckbox"
                           defaultChecked={isChecked}
                           onChange={handleOnChange}
                         />

                        { isChecked && <Row>                        
                            <Col>
                               <Row>
                                   <Col>
                                   <TextField id="hours" name="hours" type='number' label="Hours" variant="standard" value={hours} onChange={hoursHandler} />
                                   </Col>
                                   <Col>
                                   <TextField id="hours" name="hours" type='number' label="Hourly Rate" variant="standard" value={rate} onChange={rateHandler} />
                                   </Col>
                                   <Col>
                                   <Button variant="outline-warning" onClick={resetHours}>Reset Hours</Button>
                                   </Col>
                               </Row>
                            </Col>                            
                        </Row>}
                        <h6 style={{marginTop:'30px'}}>Payment by Labor</h6>
                        <input
                           type="checkbox"
                           id="myCheckbox1"
                           name="myCheckbox1"
                           defaultChecked={isCheckedlabor}
                           onChange={laborhandleOnChange}
                         />
                        {   isCheckedlabor &&
                            <Row>
                            <Col>
                               <Row>
                                   <Col>
                                        <TextField id="hours" name="hours" type='number' label="Labor" variant="standard" onChange={laborHandler} />
                                   </Col>
                                   <Col></Col>
                                   <Col>
                                   <Button variant="outline-warning" onClick={resetLabor}>Reset Labor</Button>
                                   </Col>
                               </Row>
                            </Col> 

                        </Row>}   
                    </Modal.Body>
                    {/* <Modal.Footer>
                      <Button onClick={props.onHide}>Close</Button>
                    </Modal.Footer> */}
                  </Modal>
                 <h5 className='mt-3'>{`Number: ${randomNumber}`}</h5>
                 <h5>{`Date: ${fecha}`}</h5>
            </Col>  
               </Row> 
               <Row className='m-5'>
                <Col className='text-center'><h3>Payment Information</h3></Col>
            </Row>
            <Row>
                <Col>
                <table className="table">
                      <thead>
                        
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{width:'200px', fontWeight:'bold' }}>Paid By:</td>
                          <td colSpan="6">{businessname}</td>
                        </tr>
                        {
                            isChecked === true &&
                            <tr>
                              <td  style={{width:'200px', fontWeight:'bold' }}>Amount Paid:</td>
                              <td>{`$ ${(hours * 1).toFixed(2).toString().split('.').map(f => parseInt(f)).reduce((x, y) => ((x * 60 + y) / 60) * rate).toFixed(2) }`}</td>
                              <td style={{fontWeight:'bold' }}>Date:</td>
                              <td colSpan="4" >{fecha}</td>
                            </tr>
                        }
                        {
                            isCheckedlabor === true &&
                            <tr>
                              <td  style={{width:'200px', fontWeight:'bold' }}>Amount Paid:</td>
                              <td>{`$ ${parseFloat(labor).toFixed(2)}`}</td>
                              <td className='text-center' style={{fontWeight:'bold' }}>Date:</td>
                              <td colSpan="4" >{fecha}</td>
                            </tr>
                        }
                        <tr>
                          <td style={{width:'200px', fontWeight:'bold' }}>For Payment Of:</td>
                          {/* <td colSpan="6" style={{maxWidth:'100px'}}>{forpaymentof}</td> */}
                          <td colSpan="6"><textarea style={{border:'none', maxWidth:'100%'}}  id="textarea" name="textarea" rows="2" cols="90" readOnly value={forpaymentof}/></td>
                        </tr>
                        {  isChecked === true &&
                            <tr>
                          <td style={{width:'200px', fontWeight:'bold' }}>Description:</td>
                          <td style={{fontWeight:'bold' }}>Hours:</td>
                          <td>{` ${parseFloat(hours).toFixed(2)}`}</td>
                          <td style={{fontWeight:'bold' }}>Hourly Rate:</td>
                          <td>{`$ ${parseFloat(rate).toFixed(2)}`}</td>
                          <td style={{fontWeight:'bold' }} className='text-center'>Total Amount Paid:</td>
                          <td style={{fontWeight:'bold' }}>{`$ ${(hours * 1).toFixed(2).toString().split('.').map(f => parseInt(f)).reduce((x, y) => ((x * 60 + y) / 60) * rate).toFixed(2) }`}</td>

                        </tr>
                        }
                        { isCheckedlabor === true &&
                            <tr>
                          <td style={{width:'200px', fontWeight:'bold' }}>Description:</td>                          
                          <td className='text-center' colSpan="2" style={{fontWeight:'bold' }}>Labor:</td>
                          <td>{`$ ${parseFloat(labor).toFixed(2)}`}</td>
                          <td colSpan="2"  style={{fontWeight:'bold' }} className='text-center'>Total Amount Paid:</td>
                          <td style={{fontWeight:'bold' }}>{`$ ${parseFloat(labor).toFixed(2)}`}</td>
                        </tr>
                        }
                      </tbody>
                    </table>
                    <Row>
                        <Col>
                        <h6 style={{marginTop:'50px'}}>{`Received By: ${profile.name} ${profile.lastName} `}</h6>
                        <h6 style={{marginTop:'50px'}}>Signature: ____________________</h6>
                        </Col>
                        <Col></Col>
                        <Col className='text-right'>
                             <Button className='mt-5 text-center' variant="outline-warning" onClick={print}>Print</Button>                             
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
  }

  export default CashPay
