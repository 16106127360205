import React, {useEffect, useState} from 'react'
import { useLocation, useHistory } from "react-router-dom"
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import * as  invoicesActions from '../store/actions/invoice'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import dbdata from "../db/dbdata.json"

import logo from '../assets/image/logo.png'
import { Divider } from '@material-ui/core';
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'


const Ourclientinvoice = (props) => {

    const location = useLocation()
    const item = location.state?.id

    let history = useHistory();

    function historyhandleClick() {
      history.push("/account");
    }


    const searchByPhone = useSelector(state => state.invoices.invoices)
   
    const dispatch = useDispatch()

    const [ idinvoice, setidinvoice ] = useState('')
    const [ name, setname] = useState('')
    const [ phone, setphone] = useState('')
    const [ date, setdate] = useState('')
    const [ brand, setbrand] = useState('')
    const [ make, setmake] = useState('')
    const [ model, setmodel] = useState('')
    const [ year, setyear] = useState('')
    const [ license, setlicense] = useState('')
    const [ bin, setbin] = useState('')
    const [ balance, setbalance] = useState('')
    const [ payment, setpayment] = useState('')
    const [ mileage, setmileage] = useState('')
    const [ adate, setadate] = useState('')
    const [ ddate, setddate] = useState('')
    const [ recommend, setrecommend] = useState('')

    const [des1, setdes1] = useState('')
    const [pri1, setpri1] = useState('')
    const [lab1, setlab1] = useState('')
    const [qua1, setqua1] = useState('')
    const [war1, setwar1] = useState('')

    const [des2, setdes2] = useState('')
    const [pri2, setpri2] = useState('')
    const [lab2, setlab2] = useState('')
    const [qua2, setqua2] = useState('')
    const [war2, setwar2] = useState('')


    const [des3, setdes3] = useState('')
    const [pri3, setpri3] = useState('')
    const [lab3, setlab3] = useState('')
    const [qua3, setqua3] = useState('')
    const [war3, setwar3] = useState('')

    const [des4, setdes4] = useState('')
    const [pri4, setpri4] = useState('')
    const [lab4, setlab4] = useState('')
    const [qua4, setqua4] = useState('')
    const [war4, setwar4] = useState('')

    const [des5, setdes5] = useState('')
    const [pri5, setpri5] = useState('')
    const [lab5, setlab5] = useState('')
    const [qua5, setqua5] = useState('')
    const [war5, setwar5] = useState('')

    const [des6, setdes6] = useState('')
    const [pri6, setpri6] = useState('')
    const [lab6, setlab6] = useState('')
    const [qua6, setqua6] = useState('')
    const [war6, setwar6] = useState('')
    const [amo, setamo] = useState('')


    useEffect(() => {
        const data = searchByPhone.find(x => x.id === item)
        setname(data.Name)
        setphone(data.Phone)
        setbrand(data.Brand)
        setmake(data.Make)
        setmodel(data.Model)
        setyear(data.Year)
        setlicense(data.License)
        setbin(data.Bin)

    },[searchByPhone, item])

    useEffect(() => {
        const idinvoicelast = searchByPhone ? parseInt(searchByPhone[searchByPhone.length - 1].Idinvoice) + 1 : ''        
        setidinvoice(idinvoicelast.toString())

    },[searchByPhone])


    const myChangeHandlerdate           = (event) => { setdate(event.target.value)} 
    const myChangeHandlerbalance        = (event) => { 
        setbalance(event.target.value)
    } 
    const myChangeHandlerpayment        = (event) => { setpayment(event.target.value)} 
    const myChangeHandlermileage        = (event) => { setmileage(event.target.value)} 
    const myChangeHandleradate          = (event) => { setadate(event.target.value)} 
    const myChangeHandlerddate          = (event) => { setddate(event.target.value)} 
    const myChangeHandlerrecommend      = (event) => { setrecommend(event.target.value)} 

    
  

    
      const myChangeHandlerdes1 = (event) => {
        setdes1(event.target.value);    
      }
      const myChangeHandlerdes2 = (event) => {
        setdes2(event.target.value);    
      }
      
      const myChangeHandlerdes3 = (event) => {
        setdes3(event.target.value);    
      }
      const myChangeHandlerdes4 = (event) => {
        setdes4(event.target.value);    
      }
      const myChangeHandlerdes5 = (event) => {
        setdes5(event.target.value);    
      }
      const myChangeHandlerdes6 = (event) => {
        setdes6(event.target.value);    
      }
      const myChangeHandlerpri1 = (event) => {
        setpri1(event.target.value);    
      }
      const myChangeHandlerlab1 = (event) => {
        setlab1(event.target.value);    
      }
      const myChangeHandlerqua1 = (event) => {
        setqua1( event.target.value);    
      }     
      const myChangeHandlerpri2 = (event) => {
        setpri2( event.target.value);    
      }
      const myChangeHandlerlab2 = (event) => {
        setlab2( event.target.value);    
      }
      const myChangeHandlerqua2 = (event) => {
        setqua2(event.target.value);    
      }
      const myChangeHandlerpri3 = (event) => {
        setpri3( event.target.value);    
      }
      const myChangeHandlerlab3 = (event) => {
        setlab3( event.target.value);    
      }
      const myChangeHandlerqua3 = (event) => {
        setqua3( event.target.value);    
      }
      const myChangeHandlerpri4 = (event) => {
        setpri4( event.target.value);    
      }
      const myChangeHandlerlab4 = (event) => {
        setlab4( event.target.value);    
      }
      const myChangeHandlerqua4 = (event) => {
        setqua4( event.target.value);    
      }
      const myChangeHandlerpri5 = (event) => {
        setpri5( event.target.value);    
      }
      const myChangeHandlerlab5 = (event) => {
        setlab5( event.target.value);    
      }
      const myChangeHandlerqua5 = (event) => {
        setqua5( event.target.value);    
      }
      const myChangeHandlerpri6 = (event) => {
        setpri6(event.target.value);    
      }
      const myChangeHandlerlab6 = (event) => {
        setlab6( event.target.value);    
      }
      const  myChangeHandlerqua6 = (event) => {
        setqua6( event.target.value);    
      }
      const myChangeHandlerwar1 = (event) => {
        setwar1(event.target.value);    
      }
      const myChangeHandlerwar2 = (event) => {
        setwar2(event.target.value);    
      }
      const myChangeHandlerwar3 = (event) => {
        setwar3(event.target.value);    
      }
      const myChangeHandlerwar4 = (event) => {
        setwar4(event.target.value);    
      }
      const myChangeHandlerwar5 = (event) => {
        setwar5(event.target.value);    
      }
      const myChangeHandlerwar6 = (event) => {
        setwar6(event.target.value);    
      }
     
     const myChangeHandleramo = (event) => {
        setamo( event.target.value);    
      }


       function print(){
        window.print();
    }



    const data = {
        "Idinvoice": idinvoice,
        "Name":      name,
        "Phone":     phone,
        "Date":      date,
        "Brand":     brand,
        "Make":      make,
        "Model":     model,
        "Year":      year,
        "License":   license,
        "Bin":       bin,
        "Balance":  (((((pri1 ? parseInt(pri1) : 0) * (qua1 ? parseInt(qua1) : 0))+ (lab1 ? parseInt(lab1) : 0 ) + 
                       ((pri2 ? parseInt(pri2) : 0) * (qua2 ? parseInt(qua2) : 0))+ (lab2 ? parseInt(lab2) : 0 ) +
                       ((pri3 ? parseInt(pri3) : 0) * (qua3 ? parseInt(qua3) : 0))+ (lab3 ? parseInt(lab3) : 0 ) + 
                       ((pri4 ? parseInt(pri4) : 0) * (qua4 ? parseInt(qua4) : 0))+ (lab4 ? parseInt(lab4) : 0 ) + 
                       ((pri5 ? parseInt(pri5) : 0) * (qua5 ? parseInt(qua5) : 0))+ (lab5 ? parseInt(lab5) : 0 ) + 
                       ((pri6 ? parseInt(pri6) : 0) * (qua6 ? parseInt(qua6) : 0))+ (lab6 ? parseInt(lab6) : 0 )))).toFixed(2),

        "Payment":  payment,
        "Mileage":  mileage,
        "Adate":    adate,
        "Ddate":    ddate,
        "Recommend":recommend,
          "Lines_details": [         
              {
                 "Des": des1,
                 "Pri": pri1,
                 "Qua": qua1,
                 "Lab": lab1,
                 "War": war1,
              },
              { 
                 "Des": des2,
                 "Pri": pri2,
                 "Qua": qua2,
                 "Lab": lab2,
                 "War": war2,
              },
              {
                 "Des": des3,
                 "Pri": pri3,
                 "Qua": qua3,
                 "Lab": lab3,
                 "War": war3,
              },
              {
                 "Des": des4,
                 "Pri": pri4,
                 "Qua": qua4,
                 "Lab": lab4,
                 "War": war4,
              },
              {
                 "Des": des5,
                 "Pri": pri5,
                 "Qua": qua5,
                 "Lab": lab5,
                 "War": war5,
              },
              {
                 "Des": des6,
                 "Pri": pri6,
                 "Qua": qua6,
                 "Lab": lab6,
                 "War": war6,
               }
          ]
      }  

      function sutmitHandlersave() {
        dispatch(invoicesActions.createInvoice(data))
        historyhandleClick()
    }


    return (
        <>
        <Container style={{marginTop:'40px'}}>   
            <form>
        <Row className='mb-3'>
            <Col sm={3} className='text-center'> 
            <div >
                <Link to='/account'>
                 <img               
                style={{width: '300px', height:150}}
                src={logo}
               alt='..'  /> 
                </Link>
            </div>
             </Col>
            <Col sm={6} className="text-center" > 
            <h2 className="text-center">{businessname}</h2>
                <p className="text-center">{bstreet}<br/>
                {bcity}<br/>{bphone}</p>
            </Col>
            <Col sm={3} className="text-center"> 
            <h3 >Repair Invoice</h3>
            </Col>
        </Row>
        <Divider variant="middle" />
        <Divider className='mb-3' variant="middle" />
        <Row className='mb-3'>        
            <Col md={4}> 
              <Row>
                <Col md={4}>
                  <h5 style={{marginTop:12}}>Bill To:</h5>
                </Col>
                <Col >
                <TextField  id="Name" name='Name' type='text' label="Full Name" variant="standard"  value={name}  />
                </Col>
              </Row>
              <Row>
                <Col  md={4}>
                  <h5 style={{marginTop:12}}>Phone:</h5>
                </Col>
                <Col>
                <TextField id="Phone" name="Phone" type='number' label=" " variant="standard"  value={phone} />
                </Col>
              </Row>
            </Col>
            <Col md={{ span: 4, offset: 4 }}> 
                <Row>
                    <Col md={6}>
                    <h5>Invoice No:</h5>
                    </Col>
                    <Col md={6}>
                    <TextField  id="Idinvoice" name="Idinvoice" type='number'  variant="standard"  value={idinvoice}  />
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                    <h5>Date:</h5>
                    </Col>
                    <Col md={5}>
                    <TextField  id="Date" name="Date" type='date'  variant="standard" value={date} onChange={myChangeHandlerdate} />
                    </Col>
                </Row>
            </Col>
            
        </Row>
        
        <h4>Car Information</h4>
        <Row className='mb-3'>            
            <Col md={4}>
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5>Car Name:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField  id="Brand" name="Brand" type='text' label=" "  variant="standard"  value={brand} />
                    </Col>
                </Row> 
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5>Year:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField  id="Year" name="Year" type='text' label=" " variant="standard"  value={year} />
                    </Col> 
                </Row>
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5 style={{ fontSize:18 }}>Mileage:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField style={{ width: 160 }} id="Mileage" name="Mileage" type='number' label=" " variant="standard" value={mileage}  onChange={myChangeHandlermileage} />
                    </Col> 
                </Row>                                              
            </Col>  
            <Col md={4}>
            <Row>
                    <Col md={4}>
                    <br/>
                    <h5>Make:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField style={{ width: 160 }} id="Make" name="Make" type='text' label=" " variant="standard"  value={make} />
                    </Col>
                </Row>                 
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5>LPN:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  style={{ width: 200 }} id="License" name="License" type='text' label=" " variant="standard"  value={license}  />
                  
                    </Col>
                </Row> 
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5 style={{ fontSize:18 }}>Date in:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField style={{ width:220, marginTop:17 }} size='small'  id="Adate" name="Adate" type='datetime-local'  variant="standard" value={adate} onChange={myChangeHandleradate} />
                    </Col> 
                </Row>                                 
            </Col> 
            <Col md={4}>
            <Row>
                    <Col md={4}>
                    <br/>
                    <h5>Model:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  id="Model" name="Model" type='text' label=" " variant="standard"  value={model}  />    
                    </Col>
                </Row> 
                <Row>
                    <Col md={4}>
                     <br/>
                    <h5>VIN:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  style={{ width: 180 }} id="Bin" name="Bin" type='text' label=" " variant="standard"  value={bin}  />                    
                    </Col>
                </Row>  
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5 style={{ fontSize:17 }}>Date out:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField  style={{ width:220, marginTop:17 }} size='small'  id="Ddate" name="Ddate" type='datetime-local'  variant="standard" value={ddate} onChange={myChangeHandlerddate}  />
                    </Col> 
                </Row>                                 
            </Col>           
        </Row>
        <Row className='mb-5' >
        <table className="table  table-sm ">
            <thead>
              <tr className='text-center'>
                <th style={{width:'20px', borderBottomColor:'black'}}>#</th>
                <th style={{width:'330px', borderBottomColor:'black'}}>Description</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Price $</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Quantity</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Labor $</th>
                <th style={{width:'110px', borderBottomColor:'black'}}>Warranty</th>
                <th style={{width:'100px', borderBottomColor:'black'}}>Total $</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>1</td>
                <td><TextField multiline  fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}} id="Des1" name="Des" type='text' label="" variant="standard"            value={des1} onChange={myChangeHandlerdes1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center'}}} id="Pri1" name="Pri" type='number' label="" variant="standard"  value={pri1} onChange={myChangeHandlerpri1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua1" name="Qua" type='number' label="" variant="standard" value={qua1} onChange={myChangeHandlerqua1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab1" name="Lab" type='number' label="" variant="standard" value={lab1} onChange={myChangeHandlerlab1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War1" name="War" type='text' label="" variant="standard"   value={war1} onChange={myChangeHandlerwar1} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) + (lab1 ? parseInt(lab1) : 0 )).toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>2</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des2" name="Des" type='text' label="" variant="standard"            value={des2}  onChange={myChangeHandlerdes2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri2" name="Pri" type='number' label="" variant="standard" value={pri2}  onChange={myChangeHandlerpri2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua2" name="Qua" type='number' label="" variant="standard" value={qua2}  onChange={myChangeHandlerqua2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab2" name="Lab" type='number' label="" variant="standard" value={lab2}  onChange={myChangeHandlerlab2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField  inputProps={{min: 0, style: { textAlign: 'center' }}} id="War2" name="War" type='text' label="" variant="standard"  value={war2}  onChange={myChangeHandlerwar2} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) + (lab2 ? parseInt(lab2) : 0 )).toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center' >3</td>
                <td ><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des3" name="Des" type='text' label="" variant="standard"            value={des3}   onChange={myChangeHandlerdes3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri3" name="Pri" type='number' label="" variant="standard"  value={pri3}   onChange={myChangeHandlerpri3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua3" name="Qua" type='number' label="" variant="standard"  value={qua3}   onChange={myChangeHandlerqua3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab3" name="Lab" type='number' label="" variant="standard"  value={lab3}   onChange={myChangeHandlerlab3}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War3" name="War" type='text' label="" variant="standard"    value={war3}   onChange={myChangeHandlerwar3} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) + (lab3 ? parseInt(lab3) : 0 )).toFixed(2)}</td>

              </tr> 
             
                <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>4</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des4" name="Des" type='text' label="" variant="standard"            value={des4} onChange={myChangeHandlerdes4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri4" name="Pri" type='number' label="" variant="standard" value={pri4} onChange={myChangeHandlerpri4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua4" name="Qua" type='number' label="" variant="standard" value={qua4} onChange={myChangeHandlerqua4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab4" name="Lab" type='number' label="" variant="standard" value={lab4} onChange={myChangeHandlerlab4}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War4" name="War" type='text' label="" variant="standard"   value={war4} onChange={myChangeHandlerwar4} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) + (lab4 ? parseInt(lab4) : 0 )).toFixed(2)}</td>
              </tr> 
              
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>5</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des5" name="Des" type='text' label="" variant="standard"             value={des5}  onChange={myChangeHandlerdes5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri5" name="Pri" type='number' label="" variant="standard"  value={pri5}  onChange={myChangeHandlerpri5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua5" name="Qua" type='number' label="" variant="standard"  value={qua5}  onChange={myChangeHandlerqua5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab5" name="Lab" type='number' label="" variant="standard"  value={lab5}  onChange={myChangeHandlerlab5}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War5" name="War" type='text' label="" variant="standard"    value={war5}  onChange={myChangeHandlerwar5} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) + (lab5 ? parseInt(lab5) : 0 )).toFixed(2)}</td>
              </tr> 
             
               <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>6</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des6" name="Des" type='text' label="" variant="standard"             value={des6}  onChange={myChangeHandlerdes6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri6" name="Pri" type='number' label="" variant="standard"  value={pri6}  onChange={myChangeHandlerpri6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua6" name="Qua" type='number' label="" variant="standard"  value={qua6}  onChange={myChangeHandlerqua6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab6" name="Lab" type='number' label="" variant="standard"  value={lab6}  onChange={myChangeHandlerlab6}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War6" name="War" type='text' label="" variant="standard"    value={war6}  onChange={myChangeHandlerwar6} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) + (lab6 ? parseInt(lab6) : 0 )).toFixed(2)}</td>
              </tr>                     
              <tr>
                <td className='text-center' style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black'}} className='text-left' ><h6><strong>Total $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' >
                    <h6>
                    <strong>
                    {
                        (((pri1 ? parseInt(pri1) * parseInt(qua1) : 0) + (lab1 ? parseInt(lab1) : 0 )) + 
                        ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) + (lab2 ? parseInt(lab2) : 0 )) +
                        ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) + (lab3 ? parseInt(lab3) : 0 )) + 
                        ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) + (lab4 ? parseInt(lab4) : 0 )) +
                        ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) + (lab5 ? parseInt(lab5) : 0 )) + 
                        ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) + (lab6 ? parseInt(lab6) : 0 ))).toFixed(2)

                    }
                    </strong>
                    </h6>
                </td>
              </tr>   
              <tr>
                <td className='text-center' style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderBottomColor:'white'}} ></td>
                <td style={{borderColor:'black'}} className='text-left'><h6><strong>Paid Amount $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' ><strong>
                  <TextField inputProps={{min: 0, style: { textAlign: 'center' }}}  
                  id="amo" type='number' label="" 
                  name="amo"
                  placeholder="  0.00"
                  variant="standard"  
                  value={amo}
                  onChange={myChangeHandleramo}/></strong></td>
              </tr> 
              <tr>
                <td className='text-center' style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}><Autocomplete
                      id="idpayment"
                      options={ dbdata.payment}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Payment" name="Payment" type='text' label="Payment methodo" variant="standard" value={payment} onSelect={myChangeHandlerpayment} />}
                    /> </td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-left'><h6><strong>Balance Due $</strong></h6></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-center' ><strong>
                <TextField inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 'bold', fontSize: "large" }}}  type='text' label="" variant="standard" 
                
                     value={  (((((pri1 ? parseInt(pri1) : 0) * (qua1 ? parseInt(qua1) : 0))+ (lab1 ? parseInt(lab1) : 0 ) + 
                                 ((pri2 ? parseInt(pri2) : 0) * (qua2 ? parseInt(qua2) : 0))+ (lab2 ? parseInt(lab2) : 0 ) +
                                 ((pri3 ? parseInt(pri3) : 0) * (qua3 ? parseInt(qua3) : 0))+ (lab3 ? parseInt(lab3) : 0 ) + 
                                 ((pri4 ? parseInt(pri4) : 0) * (qua4 ? parseInt(qua4) : 0))+ (lab4 ? parseInt(lab4) : 0 ) + 
                                 ((pri5 ? parseInt(pri5) : 0) * (qua5 ? parseInt(qua5) : 0))+ (lab5 ? parseInt(lab5) : 0 ) + 
                                 ((pri6 ? parseInt(pri6) : 0) * (qua6 ? parseInt(qua6) : 0))+ (lab6 ? parseInt(lab6) : 0 ))) - (amo ? parseInt(amo) : 0)).toFixed(2) }                
                                  onChange={myChangeHandlerbalance} />
                  </strong>                  
                </td>
              </tr>  
            </tbody>
            </table>
        </Row>       
        <Row className='mb-2'>
            <Col md={6} className='text-center'>
                <h5>Customer signature</h5>
            </Col>
            <Col md={6} className='text-center'>
                <h5>{businessname}</h5>                
            </Col>
        </Row>    
        <Row className='mb-5'>           
            <Col md={12} className='text-left'>
                <h6>MECHANICS RECOMMENDATIONS:</h6>
                <textarea  
                  style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                   id="Recommend" 
                   name="Recommend" 
                   type='text' 
                   value={recommend}
                   onChange={myChangeHandlerrecommend}
                />               
                  
            </Col>
        </Row> 
        <Row>
            <Col md={4} className='text-center' >
                <span><strong>{bemail}</strong></span>
            </Col>
            <Col md={4} className='text-center'>
                <h5 ><strong>Thanks for choosing us.</strong></h5>
            </Col>
            <Col md={4} >
            <Row>
                <Col>
                   <Button                                
                        type="submit"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}     
                         onClick={sutmitHandlersave}             
                        >
                         Save
                    </Button>
                </Col>
                <Col>
                    <Button    
                           onClick={print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
            </Col>
        </Row>
        </form>
      </Container>
      </>
        
    )
}

export default Ourclientinvoice

