class Invoice {
    constructor(id, Adate, Balance, Bin, Brand, Date, Ddate, Idinvoice, License, 
        Lines_details, Make, Mileage, Model, Name, Payment, Phone, Recommend, Year ) {
      this.id = id;
      this.Adate = Adate;
      this.Balance = Balance;
      this.Bin = Bin;
      this.Brand = Brand;
      this.Date = Date;
      this.Ddate = Ddate;
      this.Idinvoice = Idinvoice;
      this.License = License;
      this.Lines_details = Lines_details;
      this.Make = Make;
      this.Mileage = Mileage;
      this.Model = Model;
      this.Name = Name;
      this.Payment = Payment;
      this.Phone = Phone;
      this.Recommend = Recommend;
      this.Year = Year;
    }
  }
  
  export default Invoice;
  