import React, { useState } from 'react'
import { Container, Row, Col,Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import logoGottaWeb from '../assets/image/logoGottaWeb.png'


const GottaWebDesign = props => {

    const [showpayment, setShowpayment] = useState(false);
    const [showhistory, setShowhistory] = useState(false);

    // const[checkout, setCheckOut] = useState(false)


    
    return (
        <Container className='mt-5'>
            <Row>
                <Col>
                    <div>
                        <Link to='/account'>
                            <img style={{width: 200}} src={logoGottaWeb} alt='...'  />
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col><h2 className='text-center mb-5'>The Business behind business.</h2></Col>
            </Row>            
            <Row>
                <Col>
                    <div className="d-grid gap-2 mb-3">
                        <Button style={{width: '100%'}} variant="outline-info" size="lg" href='https://contract.gottawebdesign.com/suscription-professional-auto-repair' target='_blank' >
                            Make a payment suscription:  "auto"
                         </Button> 
                    </div>
                    <div className="d-grid gap-2 mb-3">
                        <Button style={{width: '100%'}} variant="outline-info" size="lg" href='https://contract.gottawebdesign.com/one-payment-professional-auto-repair' target='_blank' >
                            Make a payment:  "auto"
                         </Button> 
                    </div>
                </Col>
                <Col>
                <div className="d-grid gap-2 mb-3">
                       <Button style={{width: '100%'}} variant="outline-info" size="lg" onClick={() => setShowpayment(true)}>
                         Request
                       </Button>
                       <Modal
                         show={showpayment}
                         onHide={() => setShowpayment(false)}
                         aria-labelledby="contained-modal-title-vcenter"
                         centered
                         backdrop="static"
                         keyboard={false}
                         >
                         <Modal.Header closeButton>
                           <Modal.Title id="example-custom-modal-styling-title">
                                Request
                           </Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                           We are working here sorry...
                         </Modal.Body>
                         <Modal.Footer>
                          <Button onClick={() => setShowpayment(false)} variant="outline-info">Close</Button>
                        </Modal.Footer>
                       </Modal>
                    </div> 
                </Col>                
                <Col>
                <div className="d-grid gap-2 mb-3">
                       <Button style={{width: '100%'}} variant="outline-info" size="lg" onClick={() => setShowhistory(true)}>
                         History
                       </Button>
                       <Modal
                         show={showhistory}
                         onHide={() => setShowhistory(false)}
                         aria-labelledby="contained-modal-title-vcenter"
                         centered
                         backdrop="static"
                         keyboard={false}
                         >
                         <Modal.Header closeButton>
                           <Modal.Title id="example-custom-modal-styling-title">
                           History
                           </Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                           We are working here sorry...
                         </Modal.Body>
                         <Modal.Footer>
                          <Button onClick={() => setShowhistory(false)} variant="outline-info">Close</Button>
                        </Modal.Footer>
                       </Modal>
                    </div> 
                </Col>
            </Row>
        </Container>
    )
}
export default GottaWebDesign

