import Invoicetire from "../../models/Invoicetire"
export const SET_INVOICESTIRE = 'SET_INVOICESTIRE'
// export const SET_INVOICE = 'SET_INVOICE'
export const SET_MAKES = 'SET_MAKES'
export const SET_MODELS = 'SET_MODELS'
export const CREATE_INVOICETIRE = 'CREATE_INVOICETIRE'


export const createInvoicetire = (data) => {

  return async dispatch => {
      //any async code you want!
     await fetch(process.env.REACT_APP_CREATEINVOICETIRE, 
     {
       method:"POST",
       headers: {"content-type": "application/json"
     },
     body: JSON.stringify(data)
     })
     .then(() => 
         alert("Successfully updated")
         )
       .catch(() => 
          alert("Error updating field")
       )

      dispatch({ 
          type: CREATE_INVOICETIRE, 
          invoicetire: data
      })    
  }
}

export const fetchInvoicestire = () => {
    return async dispatch => {
        try {
          const response = await fetch(
            process.env.REACT_APP_FETCH_INVOICETIRE
           );
      
           if (!response.ok){
               throw new Error('Something went wrong!')
           }
      
           const resData = await response.json()
           const loadedInvoicestire = [];
             for(const key in resData) {
                loadedInvoicestire.push(new Invoicetire(
                     key, 
                     resData[key].Adate, 
                     resData[key].Balance, 
                     resData[key].Bin,
                     resData[key].Brand,
                     resData[key].Date,
                     resData[key].Ddate,
                     resData[key].Idinvoice,
                     resData[key].License,
                     resData[key].Lines_details,
                     resData[key].Make,
                     resData[key].Mileage,
                     resData[key].Model,
                     resData[key].Name,
                     resData[key].Payment,
                     resData[key].Phone,
                     resData[key].Recommend,
                     resData[key].Year,
                     ))
             }
          dispatch({ type:SET_INVOICESTIRE, invoicestire: loadedInvoicestire });
        } catch (err) {
          throw err;
        }
         
        } 
}


export const setMakes = items => {
    return {
        type: SET_MAKES, makes: items
    }
}
export const setModels = item => {
    return {
        type: SET_MODELS, models: item
    }
}