class Estimate {
    constructor(id, Idinvoice, Name, Phone, Address, Location, Date, Brand, Make, Model, Year,
        License, Bin, Mileage, Adate, Ddate, Lines_details ) {
           this.id = id
           this.Idinvoice = Idinvoice
           this.Name = Name
           this.Phone = Phone
           this.Address = Address
           this.Location = Location
           this.Date = Date
           this.Brand = Brand
           this.Make = Make
           this.Model = Model
           this.Year = Year
           this.License = License
           this.Bin = Bin
           this.Mileage = Mileage
           this.Adate = Adate
           this.Ddate = Ddate
           this.Lines_details = Lines_details
    }
  }
  
  export default Estimate;
  