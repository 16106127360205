import React, {useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import './InvoiceLast.css'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Divider, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import { Link } from 'react-router-dom';
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logo from '../assets/image/logo.png'

const SearchEstimate = () => {
    
    const [inputfiltro, setInputFiltro] = useState('')
    const [filterPhone, setFilterPhone] = useState(null)

    const searchByPhone = useSelector(state => state.estimates.estimates)


    const  onChangeHandlerInput = (event) => {
        setInputFiltro(event.target.value);   
    }

    const handlerSubmit = () => {
        setFilterPhone(searchByPhone.filter(item => item.Phone === inputfiltro))        
    }
   const print = () => {
      window.print();
    }
    return(
        <>
        <Container>
            <Row>
                <Col className='mt-2'>
                    <Row style={{margin: 5}}>
                    <Link to='/account' style={{textDecoration: 'none', marginTop:10}}>
                      <ArrowBackIcon color='primary' />
                    </Link>                    
                    <Divider variant="middle" />
                    <Autocomplete                      
                      size='small'
                      id="idPhone"
                      options={ searchByPhone}
                      getOptionLabel={(option) => option.Phone}
                      style={{ width: 180 }}
                      renderInput={(params) => <TextField {...params} id="Phone" name="Phone" type='text' label="--Phone Number-- "  variant="standard"  onSelect={onChangeHandlerInput } />}
                    /> 
                    <Divider variant="middle" />
                    <Button  
                        onClick={handlerSubmit}                              
                        type="button"
                         variant="outlined"
                         color="primary"
                         size="small"
                        //  className="primary"
                         startIcon={<SearchIcon />}                  
                        >
                         Search
                    </Button>                    
                    </Row>
                </Col>               
            </Row>
        </Container>   
        {
            filterPhone !== null &&
            filterPhone.map(x => 
        <Container style={{marginTop:'40px'}} key={x.id}>   
            <form >
        <Row className='mb-3'>
            <Col sm={3} className='text-center'> 
            <div >
                <Link to='/account'>
                <img               
                style={{width: '300px', height:150}}
                src={logo}
               alt='..'  />
                </Link>
            </div>
             </Col>
            <Col sm={6} className="text-center" > 
                <h2 className="text-center">{businessname}</h2>
                <p className="text-center">{bstreet}<br/>
                {bcity}<br/>{bphone}</p>
            </Col>
            <Col sm={3} className="text-center"> 
                <h3 >ESTIMATE</h3>
            </Col>
        </Row>
        <Divider variant="middle" />
        <Divider className='mb-3' variant="middle" />
        <Row className='mb-3'>        
            <Col md={4}> 
              <Row>                
                <Col >
                <TextField style={{ fontSize:25}} fullWidth   id="Name" name='Name' readOnly value={x.Name} type='text' label={
                      <Typography  variant="h5" > Customer Name: </Typography>
                    } variant="standard" />
                </Col>
              </Row>
              <Row>                
                <Col>
                <TextField style={{ marginTop:10, fontSize:25}} fullWidth id="Phone" name="Phone"readOnly value={x.Phone} type='number' label={
                      <Typography  variant="h5" > Contact Number: </Typography>
                    } variant="standard" />
                </Col>
              </Row>
              {/* <h4>Bill To:</h4>                
              <TextField  id="Name" name='Name' type='text' label="Full Name" variant="standard" />
              <TextField id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" /> */}
            </Col>
            <Col>
            <Row>                
                <Col >
                <TextField style={{ fontSize:25}} fullWidth  id="Address" name='Address' readOnly value={x.Address} type='text' label={
                      <Typography  variant="h5"> Street: </Typography>
                    } variant="standard" />
                </Col>
            </Row>
            <Row>                
                <Col>
                <TextField style={{ marginTop:10, fontSize:25}} fullWidth id="Location" name="Location" readOnly value={x.Location} type='text' label={
                      <Typography  variant="h5" > City / State: </Typography>
                    } variant="standard" />
                </Col>
            </Row>

            </Col>
            <Col>
            <Row>                
                <Col>  
                    <Row>
                        <Col md={7} style={{marginTop:25 }}>
                            <h5 >Estimate No:</h5>
                        </Col>
                        <Col md={5} style={{alignItems:'left', marginTop:25}}>
                            {/* <Getidestimate /> */}
                            <TextField InputProps={{ disableUnderline: true }} id="Idinvoice" name="Idinvoice" type='number'  variant="standard" readOnly value={x.Idinvoice}  />
                        </Col>
                    </Row>              
                </Col>
            </Row>
            <Row>                
                <Col >
                    <Row>
                        <Col md={4}>
                            <h5 style={{marginTop:20 }}>Date:</h5>
                        </Col>
                        <Col  md={8}>
                            <TextField style={{marginTop:20, fontSize:25}}   id="Date" name='Date' type='date' readOnly value={x.Date}  variant="standard" />

                        </Col>
                    </Row>                
                </Col>
            </Row>
            
            </Col>
            {/* <TextField  style={{width:'60%', marginTop:10, fontSize:25}}  id="Date" name="Date" type='date'  variant="standard" /> */}

            {/* <Getidinvoice />
            <TextField  id="Idinvoice" name="Idinvoice" type='number'  variant="standard" /> */}

            
            
        </Row>
        
        <h4 className='mt-5'>Car Information</h4>
        {/* <Divider variant="middle" /> */}
        <Row className='mb-3'>            
            <Col md={4}>
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5>Car Name:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField id="Brand" name="Brand" type='text' label=" " readOnly value={x.Brand} variant="standard" />
                   
                    </Col>
                </Row> 
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5>Year:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField  id="Year" name="Year" type='text' label=" " readOnly value={x.Year} variant="standard" />
                    </Col> 
                </Row>
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5 style={{ fontSize:18 }}>Mileage:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField style={{ width: 160 }} id="Mileage" name="Mileage" type='number' label=" " readOnly value={x.Mileage} variant="standard" />
                    </Col> 
                </Row>                                              
            </Col>  
            <Col md={4}>
            <Row>
                    <Col md={4}>
                    <br/>
                    <h5>Make:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  id="Make" name="Make" type='text' label=" " readOnly value={x.Make} variant="standard"  />
                    </Col>
                </Row>                 
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5>LPN:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  style={{ width: 200 }} id="License" name="License" type='text' readOnly value={x.License} label="License Plate Number" variant="standard" />
                  
                    </Col>
                </Row> 
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5 style={{ fontSize:18 }}>Date in:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField style={{ width:220, marginTop:17 }} size='small'  id="Adate" name="Adate" readOnly value={x.Adate} type='datetime-local'  variant="standard" />
                    </Col> 
                </Row>                                 
            </Col> 
            <Col md={4}>
            <Row>
                    <Col md={4}>
                    <br/>
                    <h5>Model:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  id="Model" name="Model" type='text' label=" " readOnly value={x.Model} variant="standard" />
                    </Col>
                </Row> 
                <Row>
                    <Col md={4}>
                     <br/>
                    <h5>VIN:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  style={{ width: 180 }} id="Bin" name="Bin" type='text' label=" " readOnly value={x.Bin} variant="standard" />                    
                    </Col>
                </Row>  
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5 style={{ fontSize:17 }}>Date out:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField  style={{ width:220, marginTop:17 }} size='small'  id="Ddate" name="Ddate" readOnly value={x.Ddate} type='datetime-local'  variant="standard" />
                    </Col> 
                </Row>                                 
            </Col>           
        </Row>
        <Row className='mb-5' >
        <table className="table  table-sm ">
            <thead>
              <tr className='text-center'>
                <th style={{width:'20px', borderBottomColor:'black'}}>#</th>
                <th style={{width:'330px', borderBottomColor:'black'}}>Description</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Price $</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Quantity</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Labor $</th>
                <th style={{width:'110px', borderBottomColor:'black'}}>Warranty</th>
                <th style={{width:'100px', borderBottomColor:'black'}}>Total $</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>1</td>
                <td><TextField multiline  fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}} id="Des1" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[0].Des}  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center'}}} id="Pri1" name="Pri" type='number' label="" variant="standard"  readOnly  value={x.Lines_details[0].Pri} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua1" name="Qua" type='number' label="" variant="standard" readOnly  value={x.Lines_details[0].Qua} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab1" name="Lab" type='number' label="" variant="standard" readOnly  value={x.Lines_details[0].Lab} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War1" name="War" type='text' label="" variant="standard"   readOnly  value={x.Lines_details[0].War}  /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center' >{((x.Lines_details[0].Pri ?  parseInt(x.Lines_details[0].Pri) * parseInt(x.Lines_details[0].Qua) : 0) + (x.Lines_details[0].Lab ? parseInt(x.Lines_details[0].Lab) : 0 )).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>2</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des2" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[1].Des}  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri2" name="Pri" type='number' label="" variant="standard" readOnly  value={x.Lines_details[1].Pri} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua2" name="Qua" type='number' label="" variant="standard" readOnly  value={x.Lines_details[1].Qua} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab2" name="Lab" type='number' label="" variant="standard" readOnly  value={x.Lines_details[1].Lab} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField  inputProps={{min: 0, style: { textAlign: 'center' }}} id="War2" name="War" type='text' label="" variant="standard"  readOnly  value={x.Lines_details[1].War}  /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center' >{((x.Lines_details[1].Pri ?  parseInt(x.Lines_details[1].Pri) * parseInt(x.Lines_details[1].Qua) : 0) + (x.Lines_details[1].Lab ? parseInt(x.Lines_details[1].Lab) : 0 )).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center' >3</td>
                <td ><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des3" name="Des" type='text' label="" variant="standard"           readOnly  value={x.Lines_details[2].Des} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri3" name="Pri" type='number' label="" variant="standard" readOnly  value={x.Lines_details[2].Pri}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua3" name="Qua" type='number' label="" variant="standard" readOnly  value={x.Lines_details[2].Qua}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab3" name="Lab" type='number' label="" variant="standard" readOnly  value={x.Lines_details[2].Lab}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War3" name="War" type='text' label="" variant="standard"   readOnly  value={x.Lines_details[2].War} /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[2].Pri ?  parseInt(x.Lines_details[2].Pri) * parseInt(x.Lines_details[2].Qua) : 0) + (x.Lines_details[2].Lab ? parseInt(x.Lines_details[2].Lab) : 0 )).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>

              </tr> 
             
                <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>4</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des4" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[3].Des} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri4" name="Pri" type='number' label="" variant="standard" readOnly  value={x.Lines_details[3].Pri} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua4" name="Qua" type='number' label="" variant="standard" readOnly  value={x.Lines_details[3].Qua} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab4" name="Lab" type='number' label="" variant="standard" readOnly  value={x.Lines_details[3].Lab} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War4" name="War" type='text' label="" variant="standard"   readOnly  value={x.Lines_details[3].War} /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[3].Pri ?  parseInt(x.Lines_details[3].Pri) * parseInt(x.Lines_details[3].Qua) : 0) + (x.Lines_details[3].Lab ? parseInt(x.Lines_details[3].Lab) : 0 )).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr> 
              
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>5</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des5" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[4].Des} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri5" name="Pri" type='number' label="" variant="standard" readOnly  value={x.Lines_details[4].Pri} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua5" name="Qua" type='number' label="" variant="standard" readOnly  value={x.Lines_details[4].Qua} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab5" name="Lab" type='number' label="" variant="standard" readOnly  value={x.Lines_details[4].Lab} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War5" name="War" type='text' label="" variant="standard"   readOnly  value={x.Lines_details[4].War} /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[4].Pri ?  parseInt(x.Lines_details[4].Pri) * parseInt(x.Lines_details[4].Qua) : 0) + (x.Lines_details[4].Lab ? parseInt(x.Lines_details[4].Lab) : 0 )).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr> 
             
               <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>6</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des6" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[5].Des} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri6" name="Pri" type='number' label="" variant="standard" readOnly  value={x.Lines_details[5].Pri} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua6" name="Qua" type='number' label="" variant="standard" readOnly  value={x.Lines_details[5].Qua} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab6" name="Lab" type='number' label="" variant="standard" readOnly  value={x.Lines_details[5].Lab} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War6" name="War" type='text' label="" variant="standard"   readOnly  value={x.Lines_details[5].War} /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[5].Pri ?  parseInt(x.Lines_details[5].Pri) * parseInt(x.Lines_details[5].Qua) : 0) + (x.Lines_details[5].Lab ? parseInt(x.Lines_details[5].Lab) : 0 )).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>                     
              <tr>
                <td className='text-center' style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black'}} className='text-left' ><h6><strong>Total $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' >
                    <h6>
                    <strong>
                    {
                       (((x.Lines_details[0].Pri ?  parseInt(x.Lines_details[0].Pri) * parseInt(x.Lines_details[0].Qua) : 0) + (x.Lines_details[0].Lab ? parseInt(x.Lines_details[0].Lab) : 0 )) + 
                        ((x.Lines_details[1].Pri ?  parseInt(x.Lines_details[1].Pri) * parseInt(x.Lines_details[1].Qua) : 0) + (x.Lines_details[1].Lab ? parseInt(x.Lines_details[1].Lab) : 0 )) +
                        ((x.Lines_details[2].Pri ?  parseInt(x.Lines_details[2].Pri) * parseInt(x.Lines_details[2].Qua) : 0) + (x.Lines_details[2].Lab ? parseInt(x.Lines_details[2].Lab) : 0 )) + 
                        ((x.Lines_details[3].Pri ?  parseInt(x.Lines_details[3].Pri) * parseInt(x.Lines_details[3].Qua) : 0) + (x.Lines_details[3].Lab ? parseInt(x.Lines_details[3].Lab) : 0 )) +
                        ((x.Lines_details[4].Pri ?  parseInt(x.Lines_details[4].Pri) * parseInt(x.Lines_details[4].Qua) : 0) + (x.Lines_details[4].Lab ? parseInt(x.Lines_details[4].Lab) : 0 )) + 
                        ((x.Lines_details[5].Pri ?  parseInt(x.Lines_details[5].Pri) * parseInt(x.Lines_details[5].Qua) : 0) + (x.Lines_details[5].Lab ? parseInt(x.Lines_details[5].Lab) : 0 ))).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

                    }
                    </strong>
                    </h6>
                </td>
              </tr>   
            </tbody>
            </table>
        </Row>       
        <Row className='mb-2'>
            <Col md={6} className='text-center'>
            </Col>
            <Col md={6} className='text-center mt-5'>
                <h5>{businessname}</h5>                
            </Col>
        </Row>    
        
        <Row style={{marginTop:200}}>
        <Col md={4} className='text-center' >
                <span><strong>{bemail}</strong></span>
            </Col>         
            <Col md={4} className='text-center'>
                <h5 ><strong>Thanks for choosing us.</strong></h5>
            </Col>
            <Col md={4} >
              <Row>
                <Col>
                   {/* <Button                                
                        type="submit"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}                  
                        >
                         Save
                    </Button> */}
                </Col>
                <Col>
                    <Button    
                           onClick={print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>
              </Row>               
            </Col>
        </Row>
        </form>
      </Container>
            )}
      </>
)}
export default SearchEstimate