import Expense from "../../models/expense"
export const CREATE_EXPENSE = 'CREATE_EXPENSE'
export const SET_EXPENSES = 'SET_EXPENSES'
export const DELETE_EXPENSE = 'DELETE_EXPENSE'



export const createExpense = (data) => {

    return async dispatch => {
        //any async code you want!
    await fetch(process.env.REACT_APP_CREATE_EXPENSE, 
       {
         method:"POST",
         headers: {"content-type": "application/json"
       },
       body: JSON.stringify(data)
       })
       .then(() => 
           alert("Successfully created")
           )
         .catch(() => 
            alert("Error created field")
         )

        dispatch({ 
            type: CREATE_EXPENSE, 
            expense: data
        })    
    }
  }

  export const fetchExpenses = () => {
    return async dispatch => {
        try {
          const response = await fetch(
            process.env.REACT_APP_CREATE_EXPENSE
           );
      
           if (!response.ok){
               throw new Error('Something went wrong!')
           }
      
           const resData = await response.json()
           const loadedExpenses = [];
             for(const key in resData) {
                loadedExpenses.push(new Expense(
                     key, 
                     resData[key].category, 
                     resData[key].description, 
                     resData[key].register,
                     resData[key].payment,
                     resData[key].amount,
                     resData[key].months,
                     resData[key].total,
                     resData[key].date,
                     ))
             }
          dispatch({ type:SET_EXPENSES, expenses: loadedExpenses });
        } catch (err) {
          throw err;
        }
         
        } 
    }

    export const deleteExpense = (id) => {
        return async dispatch => {
            
            const response = await  fetch(`${process.env.REACT_APP_DELETE_EXPENSE}/${id}.json`, 
            {
              method:"DELETE",
              headers: {"content-type": "application/json"}
            }).then(() =>  alert("Successfully deleted")).catch(() => alert("Error created field"))
                  

           dispatch({type: DELETE_EXPENSE, pid: id})
              
        }
        
    }


       