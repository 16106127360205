import React, { useState} from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from 'react-redux'
import * as profilesActions from '../../store/actions/profile'

function AddProfile(props) {
  let f = new Date();
    let fecha = f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear();

    let history = useHistory();
    function historyhandleClick() {
      history.push("/account");
    }

    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [license, setLicense] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')

    const dispatch = useDispatch()

    function nameHandler(e) {
        setName(e.target.value)
    }
    function lastNameHandler(e) {
        setLastName(e.target.value)
    }
    function licenseHandler(e) {
        setLicense(e.target.value)
    }
    function phoneHandler(e) {
        setPhone(e.target.value)
    }
    function emailHandler(e) {
        setEmail(e.target.value)
    }
    function addressHandler(e) {
        setAddress(e.target.value)
    }
    const data = {
        name: name,
        lastName: lastName,
        license: license,
        phone: phone,
        email: email,
        address: address,
        idate: fecha,
        sdate: '',
        active: true,
        details: ""
    }

    // const[isValid, setIsValid] = useState(false)
    // const[erroMessage, setErrorMessage] = useState('')

    // useEffect(() => {
    //   if(erroMessage === ''){
    //     setErrorMessage('')
    //   }
    // },[erroMessage])

    // function validation() {
    //   if(name.trim().length <= 2 ){
    //     setErrorMessage('Enter a Name')
    //     setIsValid(false)
    //   } else if(lastName.trim().length <= 2 ){
    //     setErrorMessage('Enter a Last Name')
    //     setIsValid(false)        
    //   } else  if(license.length <= 4 ){
    //     setErrorMessage('Enter a License')
    //     setIsValid(false)        
    //   } else if(phone.length <= 9 ){
    //     setErrorMessage('Enter a Phone Number')
    //     setIsValid(false)
    //   } else if( email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ){
    //     setErrorMessage('Enter a Valid Email')
    //     setIsValid(false)
    //   } else if(address.trim() <= 2 ){
    //     setErrorMessage('Enter a Address')
    //     setIsValid(false)
    //   } else{
    //     setIsValid(true)
    //   }
    // }


    function submitHandler() {
        dispatch(profilesActions.createProfile(data))
        historyhandleClick()
    }


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create new Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            {/* <Row><Col><h6 style={{color: 'red'}} className="text-center" >{erroMessage ? erroMessage : ''}</h6></Col></Row> */}
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Name" onChange={nameHandler} />                  
                </Form.Group>
                <Form.Group as={Col} controlId="formGridLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Last Name" onChange={lastNameHandler} />
                </Form.Group>    
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridLicense">
                <Form.Label>License No</Form.Label>
                <Form.Control type="number" placeholder="Enter License No" onChange={licenseHandler} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="number" placeholder="Enter Phone" onChange={phoneHandler} />
              </Form.Group>    
            </Row>
              <Form.Group controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="email" onChange={emailHandler} />
                </Form.Group>
                
              <Form.Group className="mb-3" controlId="formGridAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control type='text' placeholder="Address" onChange={addressHandler} />
              </Form.Group>
                
              <Button variant="info" type="button" onClick={submitHandler}>
                Submit
              </Button>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    );
  }

  export default AddProfile