import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import * as profilesActions from '../../store/actions/profile'
import {useDispatch } from 'react-redux'

function Checkin(props) {

    const id = props.idhandler 

    let history = useHistory();
    function historyhandleClick() {
      history.push("/account");
    }

    const dispatch = useDispatch()
    
    let f = new Date();
    let fecha = f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear();

    
    const [checkin, setCheckin] = useState('')


    function checkinHandler(e) {
        setCheckin(e.target.value)
    }    

    const data = {
        checkin: checkin,
        checkout: "",
        checkinbrake: "",
        checkoutbrake: "",
        idate: fecha,
        active: true,
    }


    function submitHandler() {
        dispatch(profilesActions.createProfileDetail(id, data))
        historyhandleClick()
    }


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Check In 
          </Modal.Title>
        </Modal.Header>
        { id ?
          <Modal.Body>
        <Form>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCheckin">
                  <Form.Label>Check In</Form.Label>
                  <Form.Control type="time" placeholder="Enter Check In" onChange={checkinHandler} />
                </Form.Group>
            </Row>  
              <Button variant="info" type="submit" onClick={submitHandler}>
                Submit
              </Button> 
            </Form>
        </Modal.Body> :
         <Modal.Body>
         <Form>
             <Row className="mb-3">
                 <Form.Group as={Col} controlId="ueryuece">
                   <Form.Label>You already have an entry date registered.</Form.Label>
                 </Form.Group>
             </Row>  
             <Button onClick={props.onHide}>Close</Button> 
             </Form>
         </Modal.Body>
        }
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    );
  }

  export default Checkin
