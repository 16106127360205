import React from 'react'
import MenuItem from './MenuItem'
import './Menu.css'
import { Link } from 'react-router-dom'

function Menu() {
  return (
    <div className='menu'>
     <Link style={{textDecoration:'none'}}  to="/account"><MenuItem title='Account'/></Link>
     <Link  style={{textDecoration:'none'}} to="/"><MenuItem title='Report'/></Link>
    </div>
  )
}

export default Menu