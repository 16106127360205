import Detail from "../../models/Detail"
import Profile from "../../models/Profile"

export const CREATE_PROFILE = 'CREATE_PROFILE'
export const SET_PROFILES = 'SET_PROFILES'
export const UPDATE_PROFILE_DETAIL = 'UPDATE_PROFILE_DETAIL'
export const CREATE_PROFILE_DETAIL = 'CREATE_PROFILE_DETAIL'
export const GET_PAY_ID = 'GET_PAY_ID'

export const createProfile = (data) => {

      return async dispatch => {
          //any async code you want!
         await fetch(process.env.REACT_APP_CREATE_PROFILE, 
         {
           method:"POST",
           headers: {"content-type": "application/json"
         },
         body: JSON.stringify(data)
         })
         .then(() => 
             alert("Successfully updated")
             )
           .catch(() => 
              alert("Error updating field")
           )
    
          dispatch({ 
              type: CREATE_PROFILE, 
              profiles: data
          })    
      }
    }
    //para crear details
    export const createProfileDetail = (id, data) => {

        return async dispatch => {
            //any async code you want!
           await fetch(`${process.env.REACT_APP_CREATE_PROFILE_DETAIL}/${id}/details.json`, 
           {
             method:"POST",
             headers: {"content-type": "application/json"
           },
           body: JSON.stringify(data)
           })
           .then(() => 
             alert("Creado exitosamente"),
             window.location('/profiles')
             )
           .catch(err => console.error(err))
      
            dispatch({ 
                type: CREATE_PROFILE_DETAIL, 
                createprofiledetail: data
            })    
        }
      }

    export const fetchProfiles = () => {
        return async dispatch => {
            //any async code you want!
            try {
                const response = await fetch(
                  process.env.REACT_APP_FETCH_PROFILE
                 );
    
                 if (!response.ok){
                     throw new Error('Something went wrong!')
                 }  
                
                 const resData = await response.json()
                  //  console.log(resData)
                 let loadedProfiles = [];
                 for(const key in resData) {
                   let lstDetails =[];
                   for(const key2 in resData[key].details){
                     lstDetails.push(new Detail(
                       key2,
                       resData[key].details[key2].idate,
                       resData[key].details[key2].checkin,
                       resData[key].details[key2].checkinbrake,
                       resData[key].details[key2].checkout,
                       resData[key].details[key2].checkoutbrake,
                       resData[key].details[key2].active));
                   }
                    loadedProfiles.push(new Profile(
                         key, 
                         resData[key].name, 
                         resData[key].lastName,
                         resData[key].license ,
                         resData[key].phone ,
                         resData[key].email ,
                         resData[key].address ,
                         resData[key].idate ,
                         resData[key].sdate,
                         resData[key].active,
                         lstDetails
                         ),
                         );
                 }
                 dispatch({type: SET_PROFILES, profiles: loadedProfiles})
                
            } catch (err) {
                // send to custom analytics server
                throw err;
            }
            
        }
    }

    export const updateProfileDetail = (id, item, data) => {

        return async dispatch => {
            //any async code you want!
           await fetch(`${process.env.REACT_APP_UPDATE_PROFILE_DETAIL}/${id}/details/${item}.json`, 
           {
             method:"PATCH",
             headers: {"content-type": "application/json"
           },
           body: JSON.stringify(data)
           })
           .then(() => 
             alert("Successfully updated")
             )
           .catch(() => 
              alert("Error updating field")
           )
      
            // dispatch({ 
            //     type: UPDATE_PROFILE_DETAIL, 
            //     updateprofiledetail: data
            // })    
        }
      }
      export const getpayId = id => {
        return {
          type: GET_PAY_ID,  getpayidload: id
        }        
      }
   


      

