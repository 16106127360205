import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { blogo } from '../models/European'


function Header({ isMenuOpen, setIsMenuOpen }) {
  return (
    <div className='header'>
      <div className='header__logo'>
        <Link to='/'>
          <img
            className='header__logoImg'
            src={blogo}
            alt='...'
          />
        </Link>
      </div>
      <div className='header__links'>
      </div>
      <div className='header__right'>
        <Link to='/login' className={isMenuOpen ? 'header__link--hidden' : "" }>  
          My Account
        </Link>
        <div
          className='header__menu'
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </div>
      </div>
      
    </div>
  )
}

export default Header
