import React, {useState, useEffect, Fragment} from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import './Profiles.css'
import Checkin from '../components/modalprofile/Checkin'
import { useSelector } from 'react-redux'
import AddProfile from '../components/modalprofile/AddProfile'
import Breakin from '../components/modalprofile/Breakin'
import Breakout from '../components/modalprofile/Breakout'
import Checkout from '../components/modalprofile/Checkout'
import DetailProfile from '../components/modalprofile/DetailProfile'
import Generator from '../components/modalprofile/Generator'
import { Link } from 'react-router-dom'
import logo from '../assets/image/logo.png'

const Profiles = () => {

  let f = new Date();
  let fecha = f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear();
  let fechaayer = f.getDate() - 1 + "/" + (f.getMonth() +1) + "/" + f.getFullYear();


  const [modalShow, setModalShow] = useState(false);

  const profiles = useSelector(state => state.profiles.profiles)
  const [readProfiles, setReadProfiles] = useState('')
  const [notreadProfiles, setNotReadProfiles] = useState('')


  const[breakinid, setBreakinid] = useState('')
  const[breakinitem, setBreakinitem] = useState('')

  const [modalShowCheckin, setModalShowCheckin] = useState(false);
  const [modalShowBreakin, setModalShowBreakin] = useState(false);
  const [modalShowBreakout, setModalShowBreakout] = useState(false);
  const [modalShowCheckout, setModalShowCheckout] = useState(false);
  const [modalShowDetailprofile, setModalShowDetailprofile] = useState(false);
  const [modalShowGenerator, setModalShowGenerator] = useState(false);

  useEffect(() => {  
    let findProfile = profiles.filter(s => Object.values(s.details).find(s => s.active === true ))
    let notfindProfile = profiles.filter(x => x.active === true)
    
   setReadProfiles(findProfile)
   setNotReadProfiles(notfindProfile)
  },[setReadProfiles, setNotReadProfiles, profiles])


    const [checkinid, setCheckinid] = useState('')
    function checkinButtomHandler(iditem) {  
      if(readProfiles.filter(s => s.id === iditem && Object.values(s.details).find(s => s.active === true && s.idate === fecha)).length >= 1) {
      setModalShowCheckin(false) 

      } else {
        setCheckinid(iditem)
        setModalShowCheckin(true) 

      }
    }

    function breakinButtomHandler(id, item) {  
      setBreakinid(id)
      setBreakinitem(item)
      setModalShowBreakin(true) 
    }
    function breakoutButtomHandler(id, item) {  
      setBreakinid(id)
      setBreakinitem(item)
      setModalShowBreakout(true) 
    }
    function checkoutButtomHandler(id, item) { 
      setBreakinid(id)
      setBreakinitem(item) 
      setModalShowCheckout(true) 
    }
    function detailprofileButtomHandler(id) { 
      setCheckinid(id)
      setModalShowDetailprofile(true) 
    }

    function generatorprofileButtomHandler(id) { 
      setCheckinid(id)
      setModalShowGenerator(true) 
    }

    return(
        <>
        <Container>
            <Row>
              <Col sm={4}>
                  <div style={{marginTop:25, marginBottom:50}} >
                      <Link to='/account'>
                       <img               
                      style={{width: '300px', height:150}}
                      src={logo}
                     alt='..'  /> 
                      </Link>
                  </div>

              </Col>
              <Col sm={4}>
                 <h3 className='text-center' style={{marginTop:60}}>Human Resourses</h3>
              </Col>
              <Col sm={4}>
                    <div style={{marginTop:60}} className='text-center'>
                      <Button variant="outline-info" onClick={() => setModalShow(true)}>
                        Add Profile
                      </Button>
                      <AddProfile
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                </Col>
            </Row>            
        </Container>
        <Container>
            <Row>
                <Col>
                <table className="table table-striped table-sm">
                    <thead>
                      <tr className='text-center'>
                        <th scope="col">#</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Check In</th>
                        <th scope="col">Break In</th>
                        <th scope="col">Break out</th>
                        <th scope="col">Check Out</th>
                        <th scope="col">Date</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>   
                    <tbody>                                  
                       { readProfiles ?  readProfiles.map(item =>                        
                         <tr key={item.id}>
                          <th scope="row">{readProfiles.indexOf(item) + 1}</th>
                          <td>{`${item.name} ${item.lastName}`}</td> 
                          {
                            item.details.filter(e => e.active === true && e.idate === fecha).map(s => (
                              <React.Fragment key={s.id}>
                                <td>{s.checkin }  </td>
                                <td>{s.checkinbrake }  </td>
                                <td>{s.checkoutbrake}  </td>
                                <td>{s.checkout     }  </td>
                                <td>{s.idate        }  </td>
                                <td>{
                                 <>                                     
                                 <Button variant="outline-warning" onClick={() => breakinButtomHandler(item.id, s.id)}>
                                   Break In 
                                 </Button>
                                 <Breakin
                                   idhandler={breakinid}
                                   itemhandler={breakinitem}
                                   show={modalShowBreakin}
                                   onHide={() => setModalShowBreakin(false)}
                                 />
                                 {" "}
                                 <Button variant="outline-warning" onClick={() => breakoutButtomHandler(item.id, s.id)}>
                                   Break Out 
                                 </Button>
                                 <Breakout
                                   idhandler={breakinid}
                                   itemhandler={breakinitem}
                                   show={modalShowBreakout}
                                   onHide={() => setModalShowBreakout(false)}
                                 />
                                 {" "}
                                 <Button variant="outline-danger" onClick={() => checkoutButtomHandler(item.id, s.id)}>
                                   Check Out 
                                 </Button>
                                 <Checkout
                                   idhandler={breakinid}
                                   itemhandler={breakinitem}
                                   show={modalShowCheckout}
                                   onHide={() => setModalShowCheckout(false)}
                                 />
                                 </>
                                
                                }  </td>
                                </React.Fragment>)
                              )
                          }                     
                        </tr>                                     
                         ) : 
                         <tr>
                          <th scope="row"></th>
                          <td></td>                        
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>                        
                        </tr> 
                         }                          
                         </tbody>  
                  </table>                    
                </Col>
            </Row>
        </Container>     
        <Container>
            <Row>
                <Col>                
                <h4>Yesterday's missing records</h4>
                <table className="table table-striped table-sm">
                    <thead>
                      <tr className='text-center'>
                        <th scope="col">#</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Check In</th>
                        <th scope="col">Break In</th>
                        <th scope="col">Break out</th>
                        <th scope="col">Check Out</th>
                        <th scope="col">Date</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>   
                    <tbody>                                  
                       { readProfiles ?  readProfiles.map(item =>                        
                         <tr key={item.id}>
                          <th scope="row">{readProfiles.indexOf(item) + 1}</th>
                          <td>{`${item.name} ${item.lastName}`}</td> 
                          {
                            item.details.filter(e => e.active === true && e.idate === fechaayer).map(s => (
                              <React.Fragment key={s.id}>
                                <td>{s.checkin }  </td>
                                <td>{s.checkinbrake }  </td>
                                <td>{s.checkoutbrake}  </td>
                                <td>{s.checkout     }  </td>
                                <td>{s.idate        }  </td>
                                <td>{
                                 <>                                     
                                 <Button variant="outline-warning" onClick={() => breakinButtomHandler(item.id, s.id)}>
                                   Break In 
                                 </Button>
                                 <Breakin
                                   idhandler={breakinid}
                                   itemhandler={breakinitem}
                                   show={modalShowBreakin}
                                   onHide={() => setModalShowBreakin(false)}
                                 />
                                 {" "}
                                 <Button variant="outline-warning" onClick={() => breakoutButtomHandler(item.id, s.id)}>
                                   Break Out 
                                 </Button>
                                 <Breakout
                                   idhandler={breakinid}
                                   itemhandler={breakinitem}
                                   show={modalShowBreakout}
                                   onHide={() => setModalShowBreakout(false)}
                                 />
                                 {" "}
                                 <Button variant="outline-danger" onClick={() => checkoutButtomHandler(item.id, s.id)}>
                                   Check Out 
                                 </Button>
                                 <Checkout
                                   idhandler={breakinid}
                                   itemhandler={breakinitem}
                                   show={modalShowCheckout}
                                   onHide={() => setModalShowCheckout(false)}
                                 />
                                 </>
                                
                                }  </td>
                                </React.Fragment>)
                              )
                          }                     
                        </tr>                                     
                         ) : 
                         <tr>
                          <th scope="row"></th>
                          <td></td>                        
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>                        
                        </tr> 
                         }                          
                         </tbody>  
                  </table>                    
                </Col>
            </Row>
        </Container>     
        <Container>
            <Row>
                <Col>                
                <h4>Actions</h4>
                <table className="table table-striped table-sm">
                    <thead>
                      <tr className='text-center'>
                        <th scope="col">#</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>   
                    <tbody>     
                          { notreadProfiles.length >=1 ?  notreadProfiles.map(item =>                        
                         <tr key={item.id}>
                          <th scope="row">{notreadProfiles.indexOf(item) + 1}</th>
                          <td olspan="4">{`${item.name} ${item.lastName}`}</td>                        
                          
                          
                          <td className='text-center'>
                            <>                                     
                              <Button variant="outline-info" onClick={() => checkinButtomHandler(item.id)}>
                                Check In 
                              </Button>
                              <Checkin
                                idhandler={checkinid}
                                show={modalShowCheckin}
                                onHide={() => setModalShowCheckin(false)}
                              />
                              {" "}
                              <Button variant="outline-info" onClick={() => detailprofileButtomHandler(item.id)}>
                                Detail 
                              </Button>
                              <DetailProfile
                                idhandler={checkinid}
                                show={modalShowDetailprofile}
                                onHide={() => setModalShowDetailprofile(false)}
                              />                              
                              {" "}
                              <Button variant="outline-info" onClick={() => generatorprofileButtomHandler(item.id)}>
                                Generator 
                              </Button>
                              <Generator
                                idhandler={checkinid}
                                show={modalShowGenerator}
                                onHide={() => setModalShowGenerator(false)}
                              />
                              </>
                          </td>                        
                        </tr>                                     
                         ) : 
                         <tr>
                          <th scope="row"></th>
                          <td></td>                        
                          <td></td>                    
                        </tr> 
                         }
                         </tbody>  
                  </table>                    
                </Col>
            </Row>
        </Container>              
        </>
    )
}
export  default Profiles














